export default {
  'plan.title': 'Configuration du forfait',
  'plan.title.special': 'Configuration du plan spécial',
  'plan.btn.add': 'Ajouter un forfait',
  'plan.btn.addTemporary': 'Ajouter un plan temporaire',
  'plan.btn.bind': 'Attribuer un appareil',
  'plan.btn.copy': 'Copier le plan',
  'plan.btn.paste': 'Coller le plan',
  'plan.btn.export': "Plan d'exportation",
  'plan.btn.batch': 'Calendrier des lots',
  'plan.btn.addshift': 'Ajouter un horaire',
  'plan.btn.delshift': "Supprimer l'horaire",
  'plan.btn.guard': 'Configuration de la garde',
  'plan.btn.addrest': 'Ajouter du repos',
  'plan.btn.Manu': 'Attribuer manuellement',
  'plan.modal.label.next': 'Le lendemain',
  'plan.modal.label.onetime': 'Temps de patrouille unique (minutes)',
  'plan.modal.label.resttime': 'Temps de repos (minutes)',
  'plan.modal.label.no': 'Non ',
  'plan.modal.label.day': ' journée',
  'plan.modal.label.weekrest': 'Semaine de repos',
  'plan.modal.label.specialrest': 'Repos spécial',
  'plan.modal.label.period': 'Période de patrouille',
  'plan.modal.label.unit': 'Unité',
  'plan.modal.label.plantimes': 'Planifier les heures',
  'plan.modal.label.times': 'Fois',
  'plan.modal.label.cost': 'Il est temps de patrouiller un tour',
  'plan.modal.label.interval': 'Intervalle de deux tours',
  'plan.modal.label.month': 'Mois',
  'plan.modal.label.week': 'La semaine',
  'plan.modal.label.hour': 'Heure',
  'plan.modal.label.minute': 'Minute',
  'plan.modal.label.days': 'Jour',
  'plan.table.schedule': 'Programme',
  'plan.table.restday': 'Repos',
  'plan.table.monday': 'Lundi',
  'plan.table.tuesday': 'Mardi',
  'plan.table.wednesday': 'Mercredi',
  'plan.table.thursday': 'Jeudi',
  'plan.table.friday': 'Vendredi',
  'plan.table.saturday': 'Samedi',
  'plan.table.sunday': 'Dimanche',
  'plan.table.type': 'Type de régime',
  'plan.table.per': 'Par ',
  'plan.table.month': ' mois',
  'plan.table.patrol': ' patrouille',
  'plan.tab.plan': 'Plan',
  'plan.tab.special': 'Régime spécial',
  'plan.tab.schedule': 'Horaire de patrouille',
  'plan.step.basic': 'Paramètres de base',
  'plan.step.site': 'Configuration du point de contrôle',
  'plan.step.shift': "Configuration de l'horaire",
  'plan.step.next': 'Suivant',
  'plan.step.prev': 'Précédent',
  'plan.step.submit': 'Soumettre',
  'plan.step.rest': 'Configuration du jour de repos',
  'plan.transfer.site.select': 'Points de contrôle sélectionnés',
  'plan.transfer.site.unselect': 'Points de contrôle non sélectionnés',
  'plan.input.plan.placeholder': 'Veuillez saisir le nom du plan',
  'plan.notification.description.save.success': 'Enregistrer le plan avec succès',
  'plan.notification.description.sitefirst': "Veuillez d'abord définir les points de contrôle",
  'plan.notification.description.schedulefirst': "Veuillez d'abord définir les horaires",
  'plan.notification.description.exitplan':
    'Les données de ce jour ont été créées dans ce planning. Il faut remplacer les données et recréer de nouvelles données ?',
  'plan.notification.description.than24':
    'Chaque temps de patrouille doit être dans les 24 heures.',
  'plan.notification.description.repeat': 'Temps répété',
  'plan.notification.description.date': "L'heure de fin doit être supérieure à l'heure de début",
  'plan.notification.description.exitdate':
    'La date sélectionnée existe déjà, veuillez sélectionner à nouveau',
  'plan.notification.description.exitdevice':
    "L'appareil existe déjà. Veuillez ne pas l'ajouter à nouveau",
  'plan.notification.description.appuser':
    'La garde doit être définie dans le programme de patrouille',
  'plan.notification.description.copy.success': 'Copie du plan réussie',
  'plan.notification.description.copyfirst': "Veuillez d'abord copier le plan",
  'plan.notification.description.selfirst': 'Veuillez sélectionner un forfait',
  'plan.notification.description.selone': 'Veuillez sélectionner un enregistrement à exploiter',
  'plan.notification.description.patrolone':
    "Le nombre de temps de patrouille est une fois et le temps d'intervalle doit être mis à zéro",
  'plan.Modal.confirm.paste':
    'Êtes-vous sûr de vouloir coller le plan copié sous la société actuelle?',
  'plan.Modal.confirm.restday': "Veuillez d'abord sélectionner un jour de repos.",
  'plan.shift.day.wrong':
    'Les horaires quotidiens ne peuvent être créés que pour un maximum de 31 jours. Veuillez revenir en patrouille!',
  'plan.shift.week.wrong':
    'Un horaire hebdomadaire ne peut être créé que pour un maximum de 4 semaines. Veuillez revenir en patrouille!',
  'plan.shift.month.wrong':
    'Un plan mensuel ne peut être créé que pour une période maximale de deux mois. Veuillez revenir en patrouille!',
  'plan.rest.wrong':
    'Impossible de définir tout comme un jour de repos hebdomadaire, veuillez Réinitialiser',
  'plan.bin.title': 'Lier un plan existant',
  'plan.bin.wrong': 'Le plan a été lié à d’autres plans temporaires et ne peut pas effectuer cette action',
  'plan.bin.tooltip': 'Les plans liés cesseront de produire des rapports d’inspection jusqu’à la fin de ce plan temporaire',
};
