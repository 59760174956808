export default {
  'sound.title': 'Voice Setup',
  'sound.no': 'No.',
  'sound.name': 'Voice Content',
  'sound.name.placeholder': 'Please input voice content',
  'sound.dept.placeholder': 'Please select company',
  'sound.table.operate': 'Operations',
  'sound.table.edit': 'Edit',
  'sound.table.add': 'Add Voice',
  'sound.table.home': 'Return Home',
  'sound.table.voice': 'Generate Voice',
  'sound.table.clear': 'Clear Device Voice',
  'sound.table.voice.success': 'Voice generated successfully!',
  'sound.table.voice.err': 'Voice generated failed',
  'sound.table.delete': 'Delete',
  'sound.table.selected': 'Selected',
  'sound.table.item': 'item',
  'sound.modal.delconfirm': 'Are you sure you want to delete the selected data?',
  'sound.table.deletesuccess': 'Successfully deleted',
  'sound.modal.ok': 'Save',
  'sound.modal.cancel': 'Cancel',
  'sound.modal.error': 'Operation Failed',
  'sound.message.loading.add': 'Adding...',
  'sound.message.loading.edit': 'Configuring...',
  'sound.genvoice.loading': 'loading...',
  'sound.genvoice.invalidDevice': 'Current device do not support voice...',
};
