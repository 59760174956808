export default {
  'alarm.notification.title': "Avis d'alarme",
  'alarm.notification.desc': '{count} alarm(s) received,please check...',

  'alarm.notification.view': 'Des détails',
  'alarm.notification.ignore': 'Ignorer',

  'alarm.notification.list': 'Alarmes non lues',
  'alarm.notification.readAll': 'Lis tout',
  'alarm.notification.readAll.confirm': 'Confirmer pour lire toutes les alarmes?',

  'alarm.notification.read.title': 'Avis',
  'alarm.notification.read.done': "Fait pour gérer l'alarme(s)",
  'alarm.notification.read.error': "Impossible de gérer l'alarme(s)",

  'alarm.manual': 'Alarme manuelle',
  'alarm.low': 'Alarme de faible puissance',
  'alarm.omit': "Omettre l'alarme",
  'alarm.tilt': "Alarme d'inclinaison",
  'alarm.motionless': 'Alarme immobile',
  'alarm.collision': 'Alarme de collision',
  'alarm.dismantle': "Démonter l'alarme",

  'alarm.choice.no': "Pas d'alarme",
  'alarm.choice.web': 'Alertes Web seulement',
  'alarm.choice.app': 'Alarme portative seulement',
  'alarm.choice.all': 'Alertes Web et manuelles',
  'alarm.notification.title.desc':
    "La page Web ou le terminal portatif contient - il une fonction de rappel des données d'alarme?",
  'alarm.out': 'Alarme hors cercle',
  'alarm.in': 'Alarme d’entrée de cercle',
};
