import menu from './el-GR/menu';
import pwa from './el-GR/pwa';
import exception from './el-GR/exception';
import request from './el-GR/request';
import login from './el-GR/login';
import dashboard from './el-GR/dashboard';
import header from './el-GR/header';
import general from './el-GR/general';
import map from './el-GR/map';
import device from './el-GR/device';
import system from './el-GR/system';
import sound from './el-GR/sound';
import communication from './el-GR/communication';
import alarm from './el-GR/alarm';
import dept from './el-GR/dept';
import event from './el-GR/event';
import guard from './el-GR/guard';
import site from './el-GR/site';
import role from './el-GR/role';
import talk from './el-GR/talk';
import user from './el-GR/user';
import content from './el-GR/content';
import book from './el-GR/book';
import receipt from './el-GR/receipt';
import plan from './el-GR/plan';
import query from './el-GR/query';
import common from './el-GR/common';
import bi from './el-GR/bi';
import company from './el-GR/company';
import enclosure from './el-GR/enclosure';
import module from './el-GR/module';
import backup from './el-GR/backup';

export default {
  ...menu,
  ...pwa,
  ...exception,
  ...request,
  ...login,
  ...dashboard,
  ...header,
  ...general,
  ...map,

  ...device,
  ...system,
  ...sound,

  ...communication,
  ...alarm,
  ...dept,
  ...event,
  ...guard,
  ...site,
  ...common,
  ...user,
  ...role,
  ...content,
  ...book,
  ...plan,
  ...receipt,
  ...query,
  ...talk,
  ...enclosure,
  ...backup,

  ...bi,
  ...company,

  ...module,

  app: 'Cloud',
  appMobile: 'Cloud',
  producer: 'JWM Hi-Tech Development Ltd.',
  oldaddress: 'Old version address',
  sys_notification:
    'Λόγω των πιθανών επιπτώσεων της αναβάθμισης των υποκείμενων υπηρεσιών υλικού της πλατφόρμας στο σύστημα, το σύστημα θα διατηρηθεί και θα αναβαθμιστεί νωρίς το πρωί του 3ου Απριλίου, ώρα Πεκίνου. Ο χρόνος αναβάθμισης αναμένεται να είναι μία ώρα. Κατά τη διάρκεια της αναβάθμισης του συστήματος, τα δεδομένα δεν θα μεταφορτωθούν.',
};
