export default {
  'alarm.notification.title': 'Ειδοποίηση συναγερμού',
  'alarm.notification.desc': '{count} συναγερμού(ων) που ελήφθησαν,παρακαλώ ελέγξτε...',

  'alarm.notification.view': 'Λεπτομέρειες',
  'alarm.notification.ignore': 'Αγνόηση',

  'alarm.notification.list': 'μη αναγνωσμένοι συναγερμοί',
  'alarm.notification.readAll': 'Ανάγνωση όλων',
  'alarm.notification.readAll.confirm': 'Επιβεβαίωση ανάγνωσης όλων τω ν συναγερμών;',

  'alarm.notification.read.title': 'Ειδοποίηση',
  'alarm.notification.read.done': 'έχει γίνει διαχείριση συναγερμών',
  'alarm.notification.read.error': 'Αποτυχία διαχείρισης συναγερμού(ων)',

  'alarm.manual': 'Χειροκίνητος συναγερμός',
  'alarm.low': 'Συναγερμός χαμηλής τάσης',
  'alarm.omit': 'Συναγερμός παράλειψης',
  'alarm.tilt': 'Συναγερμός Κλίσης',
  'alarm.motionless': 'Συναγερμός ακινησίας',
  'alarm.collision': 'Συναγερμός Σύγκρουσης',
  'alarm.dismantle': 'Συναγερμός αποσυναρμολόγησης',

  'alarm.choice.no': 'Δεν υπάρχει προτροπή συναγερμού',
  'alarm.choice.web': 'Ειδοποιήσεις μόνο σε σελίδα Web',
  'alarm.choice.app': 'μονο στο τερματικό χειρός',
  'alarm.choice.all': 'Ειδοποιήσεις σε Web και τερματικό χειρός',
  'alarm.notification.title.desc':
    'Είτε η λειτουργία υπενθύμισης δεδομένων συναγερμού περιλαμβάνεται στην ιστοσελίδα ή στο τερματικό χειρός',
  'alarm.out': 'Συναγερμός εκτός κύκλου',
  'alarm.in': 'Συναγερμός για είσοδο στον κύκλο',
};
