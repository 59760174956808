export default {
  confirm: 'Επιβεβαίωση',
  cancel: 'Ακύρωση',
  back: 'Πίσω',
  'general.homeSwitch.title.old': 'Δεν σας αρέσει το νέο Dashboard; Θέλετε να αλλάξετε στο παλιό;',
  'general.homeSwitch.title.new': 'Θέλετε να δοκιάσετε το νέο Dashboard?',
  'general.WePatrol.qr.download.text': 'Σκανάρετε για λήψη του WePatrol',
  'general.WePatrol.qr.use.text': 'Χρησιμοποιείστε [ {code} ] Σαν κωδικό καταχώρησης και σύνδεσης',
  'general.WePatrol.qr.login.text': 'Σκανάρετε για καταχώρηση και σύνδεση',
  'general.guest.alert':
    'Λειτουργία επισκέπτη που εμφανίζει τυχαία δημιουργημένα εικονικά δεδομένα μόνο για αναφορά...',
  'general.unhandled.alert': 'Υπάρχουν {count} εγγραφές σε αναμονή για διαχείριση...',
};
