export default {
  'guard.title': 'Guard Setup',
  'guard.btn.batch': 'Batch Read',
  'guard.btn.add': 'Add Guard',
  'guard.btn.edit': 'Edit Guard',
  'guard.btn.del': 'Delete Guard',
  'guard.btn.import': 'Import Guard',
  'guard.btn.export': 'Export Guard',
  'guard.btn.home': 'Return Home',
  'guard.table.code': 'Guard ID',
  'guard.table.name': 'Guard Name',
  'guard.table.operate': 'Operate',
  'guard.table.edit': 'Edit',
  'guard.app.device': 'Device List',
  'guard.app.placeholder': 'Please select device',
  'guard.finger.name': 'Fingerprint',
  'guard.finger.coll': 'Collect Fingerprint',
  'guard.finger.coll.success': 'Collect fingerprint successfully',
  'guard.finger.coll.failed': 'Collect fingerprint failed',
  'guard.finger.download': 'Download Fingerprint',
  'guard.finger.download.success': 'Fingerprint download successfully',
  'guard.finger.download.failed': 'Fingerprint download failed',
  'guard.finger.download.deldata':
    'Download fingerprint failed, before downloading the fingerprint, please delete the data in the device.',
  'guard.finger.download.nofinger': 'There is no fingerprint information to download.',
  'guard.input.guard.placeholder': 'Please input guard name',
  'guard.finger.onlyOneLine': 'Only one line can be edited at the same time',
  'guard.table.moveup': 'Move Up',
  'guard.table.movedown': 'Move Down',
};
