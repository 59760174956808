export default {
  'talkback.group': '对讲群组',
  'talkback.btn.add': '增加群组',
  'talkback.checkbox.onlySearchCurrentDept': '只显示当前区域数据',
  'talkback.table.name': '群组名称',
  'talkback.table.remarks': '群组描述',
  'talkback.transfer.guards.select': '已选人员',
  'talkback.transfer.guards.unselect': '待选人员',
  'talkback.table.name.tooltip': '仅支持中文、字母、数字和下划线',
  'talkback.intercom.tab.group': '群组对讲',
  'talkback.intercom.tab.voice': '语音对讲',
  'talkback.intercom.tab.exigency': '应急对讲',
  'talkback.intercom.tab.invite': '邀请列表',
  'talkback.intercom.tab.tmp': '临时房间',
  'talkback.intercom.status.callingCount': '对讲数量',
  'talkback.intercom.status.onlineCount': '在线数量',
  'talkback.intercom.status.sumCount': '人员总数',
  'talkback.intercom.status.callingText': '对讲中',
  'talkback.intercom.status.end': '已结束',
  'talkback.intercom.status.notCallingText': '未开启',
  'talkback.intercom.operation.call': '呼叫',
  'talkback.intercom.operation.join': '加入',
  'talkback.person.status.online': '在线状态',
  'talkback.person.status.calling': '对讲状态',
  'talkback.invite.table.person': '邀请人',
  'talkback.invite.table.datetime': '邀请时间',
  'talkback.record.table.rootName': '房间名称',
  'talkback.record.form.title': '参加人员列表',
  'talkback.record.form.search': '查看参加人员',
  'talkback.invite.operation.accpet': '接受',
  'talkback.invite.operation.refuse': '拒绝',
};
