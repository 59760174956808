export default {
  // 表格
  'company.table.index': 'No',
  'company.table.buy.count': 'Purchase Quantity',
  'company.table.remain.count': 'Remain Quantity',
  'company.table.term': 'Use Time',
  'company.table.buy.time': '购买时间',
  'company.table.status': 'State',
  'company.table.device.type': 'Device Type',
  'company.table.operate': 'Operate',
  'company.table.name': 'Company Name',
  'company.table.code': 'Company Code',
  'company.table.software.version': 'Software Version',
  'company.table.begin.date': 'Begin Time',
  'company.table.end.date': 'End Time',
  'company.table.sales.man': '销售人员',
  'company.table.device.model': 'Device Model',
  'company.add.phone.app.login': 'Login to mobile app',
  'company.table.device.count': 'Device Number',
  'company.table.expire.query': 'Expiration query',
  'company.table.date.filer': 'Date filtering',
  // 企业
  'company.info.list': 'Company List',
  'company.save': 'Save',
  'company.yes': 'Yes',
  'company.no': 'No',
  'company.edit': 'Edit',
  'company.version.all': 'All versions',
  'company.version.formal': 'Official edition',
  'company.version.try': 'Trial version',
  'company.version.demo': 'demonstration edition',
  'company.version.network': 'Network version',
  'company.version.free': 'Free version',
  'company.all': 'All companys',
  'company.soon.expire': 'Expiring soon',
  'company.already.expire': 'Has expired',
  'company.adding': 'Adding...',
  'company.add.fail': '新增企业失败',
  'company.changing': 'Editing...',
  'company.change.fail': '修改企业失败',
  'company.btn.add': 'Add Company',
  'company.btn.card': '充值卡信息',
  'comany.btn.delete': 'Delete Company',
  'company.delete.tips': 'Sure to delete the record?',
  'company.reset.password': 'Reset Password',
  'company.reset.password.select': 'Please select the record to reset the password first',
  'company.reset.password.success': 'Password reset successful',
  'company.renewal.customer': '续费客户信息',
  'company.register.device.all': 'Device List',
  'company.register.device.soon': '即将到期设备信息',
  'company.register.device.expire': '已经到期设备信息',
  'company.register.company.count': '当前注册企业数量',
  'company.register.soon.count': '即将到期企业数量',
  'company.register.expire.count': '已经到期企业数量',
  'company.register.device.all.count': '当前注册设备数量',
  'company.register.device.soon.count': '即将到期设备数量',
  'company.register.device.expire.count': '已经到期设备数量',
  'company.search': 'Query',
  'company.reset': 'Reset',
  'company.filter': 'Filter',
  'company.recharge.device.count': '充值设备数量',
  'company.renewal.time': '续费时间',
  'company.renewal.duration': '续费时长',
  // 充值卡
  'company.card.day': 'Day',
  'company.card.month': 'Month',
  'company.card.add.month': 'Month',
  'company.card.year': 'Year',
  'company.card.stop': 'Disabled',
  'company.card.start': 'Enable',
  'company.card.delete.fail': '该充值卡已被使用，不能删除！',
  'company.card.delete.tips': '确定要删除选定的充值卡信息吗？',
  'company.card.add.fail': '新增充值卡失败',
  'company.card.info.setting': '充值卡信息设置',
  'company.card.pls.buy.count': '请输入购买数量',
  'company.card.pls.term': '请输入使用期限',
  'company.card.term.unit': '期限单位',
  'company.card.select.sale.device.model': 'Please select device modal',
  'company.card.device.model.not': '企业设备型号为空，无法添加充值卡信息！',
  // 统计
  'company.device.type.statistics': '设备类型统计',
  'company.sales.man.statistics': '销售人员统计',
  'company.sales.device.statistics': '新增设备统计',
  'company.sales.device.count.statistics': '月销售设备数量统计',
  'company.sales.device.count': '销售设备数量',
  'company.new.customers.statistics': '新增客户统计',
  'company.customers.register.count': '客户注册数量',
  'company.new.customers.info.statistics': '新增客户信息统计',
  'company.system.access.statistics': '系统访问记录',
  'company.sales.man.info.statistics': '销售人员信息统计',
  'company.date': 'Date',
  'company.login.count': '登录次数',
  'company.one.month.login.count': '过去一个月码云系统登录频次统计',
  // 新增企业
  'company.add.set': 'Company Setting',
  'company.add.basics': 'Basic information',
  'company.add.module.share': 'Mobile cannot be shared with other device',
  'company.add.country.code.fail': '国家代码输入不正确，请重新输入！',
  'company.add.pls.name': 'Input Company Name',
  'company.add.pls.code': 'Input Company Code',
  'company.add.pls.model': 'Input Device Model',
  'company.add.wrongful.code': 'Illegal company code',
  'company.add.code.length': 'Company code length cannot be less than four digits',
  'company.add.contact': 'Contact',
  'company.add.pls.contact': 'Input Contact',
  'company.add.contact.tel': 'Phone',
  'company.add.pls.contact.tel': 'Input Phone',
  'company.add.email': 'Email',
  'company.add.pls.email': 'Input Email',
  'company.add.bengin.time': 'Begin Time',
  'company.add.end.time': 'End Time',
  'company.add.province': 'Province',
  'company.add.city': 'City',
  'company.add.pls.city': 'Input City',
  'company.add.register.time': 'Registration Time',
  'company.add.sale.device.model': 'Device Modal',
  'company.add.configure': 'Configuration information',
  'company.add.read.type': 'Upload data mode',
  'company.add.omit.push.func': 'Push function',
  'company.add.create.user': 'Opening personnel',
  'company.add.time.zone': 'Time Zone',
  'company.add.time.zone.des': 'Please keep consistent with your local time zone',
  'company.add.country.code': 'Country Code',
  'company.add.country.app': 'Max Mobile Users',
  'company.add.pls.country.code': 'Input Country Code',
  'company.add.show.device.model': 'Display device model',
  'company.add.no.show.device.model': 'Do not display device model',
  'company.add.data.package': 'Packet Mode',
  'company.add.base64.mode': 'Base64 Mode',
  'company.add.no.app.push.func': 'No app push function',
  'company.add.app.push.func': 'With app push function',
  'company.add.charge.or.not': 'Charge or not',
  'company.add.no.charge': 'No Charge',
  'company.add.charge': 'Charge',
  'company.add.companycode.info':
    'Cannot use Guest or Administrator as company code, please enter again!',
  // 基础数据信息
  'company.basics.info': 'Basic data',
  'company.basics.dept.name': 'Dept Name',
  'company.basics.site.list': 'Checkpoint List',
  'company.basics.site.name': 'Checkpoint Name',
  'company.basics.site.code': 'Checkpoint ID',
  'company.basics.site.delete.tips': 'Sure to delete the record ?',
  'company.basics.user.list': 'Guard List',
  'company.basics.user.name': 'Guard Name',
  'company.basics.user.code': 'Guard ID',
  'company.basics.user.delete.tips': 'Sure to delete the record ?',
  'company.basics.event.list': 'Event List',
  'company.basics.event.name': 'Event Name',
  'company.basics.event.code': 'Event ID',
  'company.basics.user.event.tips': 'Sure to delete the record ?',
  // 设备
  'company.device.change.end.time.success': 'Successfully modified',
  'company.device.number': 'Device Code',
  'company.device.end.time': 'End Time',
  'company.device.belonging.enterprise': 'Company',
  'company.device.delete.info.tips': 'Sure to delete the record ?',
  'company.device.change.end.time.tips': 'Sure to modify the end time of the registered device?',
  'company.device.register.count': 'Number of devices registered',
  'company.device.register.info.statistics': 'Statistics of registered devices',
};
