export default {
  // 表格
  'company.table.index': '序号',
  'company.table.buy.count': '购买数量',
  'company.table.remain.count': '剩余数量',
  'company.table.term': '使用期限',
  'company.table.buy.time': '购买时间',
  'company.table.status': '状态',
  'company.table.device.type': '设备类型',
  'company.table.operate': '操作',
  'company.table.name': '企业名称',
  'company.table.code': '企业编码',
  'company.table.software.version': '软件版本',
  'company.table.begin.date': '开始时间',
  'company.table.end.date': '截止时间',
  'company.table.sales.man': '销售人员',
  'company.table.device.model': '设备型号',
  'company.table.device.count': '设备数量',
  'company.table.expire.query': '到期查询',
  'company.table.date.filer': '日期过滤',
  // 企业
  'company.info.list': '企业信息列表',
  'company.save': '保存',
  'company.yes': '是',
  'company.no': '否',
  'company.edit': '编辑',
  'company.version.all': '全部版本',
  'company.version.formal': '正式版',
  'company.version.try': '试用版',
  'company.version.demo': '演示版',
  'company.version.network': '网络注册版',
  'company.version.free': '免费版',
  'company.all': '全部企业',
  'company.soon.expire': '即将到期企业',
  'company.already.expire': '已经到期企业',
  'company.adding': '正在新增...',
  'company.add.fail': '新增企业失败',
  'company.changing': '正在修改...',
  'company.change.fail': '修改企业失败',
  'company.btn.add': '新增企业',
  'company.btn.card': '充值卡信息',
  'comany.btn.delete': '删除企业',
  'company.delete.tips': '确定要删除选定的企业吗？',
  'company.reset.password': '重置密码',
  'company.reset.password.select': '请先选择要重置密码的企业',
  'company.reset.password.success': '重置密码成功',
  'company.renewal.customer': '续费客户信息',
  'company.register.device.all': '全部注册设备信息',
  'company.register.device.soon': '即将到期设备信息',
  'company.register.device.expire': '已经到期设备信息',
  'company.register.company.count': '当前注册企业数量',
  'company.register.soon.count': '即将到期企业数量',
  'company.register.expire.count': '已经到期企业数量',
  'company.register.device.all.count': '当前注册设备数量',
  'company.register.device.soon.count': '即将到期设备数量',
  'company.register.device.expire.count': '已经到期设备数量',
  'company.search': '查询',
  'company.reset': '重置',
  'company.filter': '筛选',
  'company.recharge.device.count': '充值设备数量',
  'company.renewal.time': '续费时间',
  'company.renewal.duration': '续费时长',
  // 充值卡
  'company.card.day': '日',
  'company.card.month': '个月',
  'company.card.add.month': '月',
  'company.card.year': '年',
  'company.card.stop': '停用',
  'company.card.start': '启用',
  'company.card.delete.fail': '该充值卡已被使用，不能删除！',
  'company.card.delete.tips': '确定要删除选定的充值卡信息吗？',
  'company.card.add.fail': '新增充值卡失败',
  'company.card.info.setting': '充值卡信息设置',
  'company.card.pls.buy.count': '请输入购买数量',
  'company.card.pls.term': '请输入使用期限',
  'company.card.term.unit': '期限单位',
  'company.card.select.sale.device.model': '请选择销售设备型号',
  'company.card.device.model.not': '企业设备型号为空，无法添加充值卡信息！',
  // 统计
  'company.device.type.statistics': '设备类型统计',
  'company.sales.man.statistics': '销售人员统计',
  'company.sales.device.statistics': '销售设备统计',
  'company.sales.device.count.statistics': '月销售设备数量统计',
  'company.sales.device.count': '销售设备数量',
  'company.new.customers.statistics': '新增客户统计',
  'company.customers.register.count': '客户注册数量',
  'company.new.customers.info.statistics': '新增客户信息统计',
  'company.system.access.statistics': '系统访问记录',
  'company.sales.man.info.statistics': '销售人员信息统计',
  'company.date': '日期',
  'company.login.count': '登录次数',
  'company.one.month.login.count': '过去一个月码云系统登录频次统计',
  // 新增企业
  'company.add.set': '企业信息设置',
  'company.add.basics': '基础信息',
  'company.add.module.share': '巡检宝设备不能与其他设备共用！',
  'company.add.country.code.fail': '国家代码输入不正确，请重新输入！',
  'company.add.pls.name': '请输入企业名称',
  'company.add.pls.code': '请输入企业编码',
  'company.add.pls.model': '请输入设备型号',
  'company.add.wrongful.code': '不合法的企业编码',
  'company.add.code.length': '企业编码长度不能小于四位',
  'company.add.contact': '联系人',
  'company.add.pls.contact': '请输入联系人',
  'company.add.contact.tel': '联系电话',
  'company.add.pls.contact.tel': '请输入联系电话',
  'company.add.email': '电子邮箱',
  'company.add.pls.email': '请输入电子邮箱',
  'company.add.bengin.time': '开始使用时间',
  'company.add.end.time': '截止使用时间',
  'company.add.province': '所在省份',
  'company.add.city': '所在城市',
  'company.add.pls.city': '请输入所在城市',
  'company.add.register.time': '注册时间',
  'company.add.sale.device.model': '销售设备型号',
  'company.add.phone.app.login': '手机APP登录',
  'company.add.configure': '配置信息',
  'company.add.read.type': '读取数据方式',
  'company.add.omit.push.func': '推送功能',
  'company.add.create.user': '开通人员',
  'company.add.time.zone': '所处时区',
  'company.add.time.zone.des': '请与当地时区保持一致',
  'company.add.country.code': '国家代码',
  'company.add.country.app': '巡检宝最大用户数',
  'company.add.pls.country.code': '请输入国家代码',
  'company.add.show.device.model': '显示设备型号',
  'company.add.no.show.device.model': '不显示设备型号',
  'company.add.data.package': '数据包方式',
  'company.add.base64.mode': 'Base64方式',
  'company.add.no.app.push.func': '无APP端推送功能',
  'company.add.app.push.func': '有APP端推送功能',
  'company.add.charge.or.not': '是否收费',
  'company.add.no.charge': '不收费',
  'company.add.charge': '收费',
  'company.add.companycode.info': '不能使用 guest 或 administrator 作为企业编码，请重新输入！',
  // 基础数据信息
  'company.basics.info': '基础数据信息',
  'company.basics.dept.name': '区域名称',
  'company.basics.site.list': '地点列表',
  'company.basics.site.name': '地点名称',
  'company.basics.site.code': '地点卡号',
  'company.basics.site.delete.tips': '确定要删除选定的地点信息吗？',
  'company.basics.user.list': '人员列表',
  'company.basics.user.name': '人员名称',
  'company.basics.user.code': '人员卡号',
  'company.basics.user.delete.tips': '确定要删除选定的人员信息吗？',
  'company.basics.event.list': '事件列表',
  'company.basics.event.name': '事件名称',
  'company.basics.event.code': '事件卡号',
  'company.basics.user.event.tips': '确定要删除选定的事件信息吗？',
  // 设备
  'company.device.change.end.time.success': '修改设备结束时间成功',
  'company.device.number': '设备号码',
  'company.device.end.time': '结束时间',
  'company.device.belonging.enterprise': '所属企业',
  'company.device.delete.info.tips': '确定要删除选定的设备信息吗？',
  'company.device.change.end.time.tips': '确定要修改注册设备结束时间？',
  'company.device.register.count': '设备注册数量',
  'company.device.register.info.statistics': '注册设备信息统计',
};
