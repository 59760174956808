export default {
  confirm: 'Confirmer',
  cancel: 'Annuler',
  back: 'Arrière',
  'general.homeSwitch.title.old':
    "Vous n'aimez pas le nouveau tableau de bord ? passer à l'ancien.",
  'general.homeSwitch.title.new': 'Essayez le nouveau tableau de bord conçu?',
  'general.WePatrol.qr.download.text': 'Scannez pour télécharger WePatrol',
  'general.WePatrol.qr.use.text':
    "Utilisez [{code}] comme code d'inscription pour vous inscrire et vous connecter",
  'general.WePatrol.qr.login.text': 'Scannez pour vous inscrire et vous connecter',
  'general.guest.alert':
    'Mode invité affichant des données fictives générées de manière aléatoire à titre de référence uniquement...',
  'general.unhandled.alert': 'Il y a {count} enregistrement(s) en attente de traitement...',
};
