export default {
  'device.title': '参数设置',
  'device.system': '系统设置',
  'device.device': '设备设置',
  'device.modal.title': '提示',
  'device.modal.ok': '确定',
  'device.modal.edit': '编辑',
  'device.modal.save': '保存',
  'device.modal.del': '删除',
  'device.modal.cancel': '取消',
  'device.modal.operate': '操作',
  'device.modal.setsuccess': '设置成功',
  'device.modal.setsuccessandreminder': '设置成功, 下载指纹后才能使用',
  'device.modal.seterr': '设置失败',
  'device.modal.delconfirm': '确定要删除选择的数据吗？',
  'device.modal.delsuccess': '数据删除成功！',
  'device.modal.delerr': '数据删除失败！',
  'device.system.basic': '基本参数',
  'device.system.basic.event': '事件功能',
  'device.system.basic.cycle': '按次计划',
  'device.system.basic.cost': '巡检用时',
  'device.system.basic.cost.des': '是否在巡检报告中显示计划的每圈巡检用时',
  'device.system.basic.stay': '停留时间',
  'device.system.basic.stay.des': '是否在巡检报告中显示每个点的停留时间',
  'device.system.basic.order': '顺序巡检',
  'device.system.basic.order.des': '是否在巡检报告中显示每个点是否按照计划设置的顺序执行',
  'device.system.basic.date': '日期格式',
  'device.system.basic.enable': '启用',
  'device.system.basic.disenable': '禁用',
  'device.device.btn.parameter': '设备参数设置',
  'device.device.btn.parameter.remote': '远程参数设置',
  'device.device.btn.delalldevicedata': '确定要清除设备中的全部数据吗？',
  'device.device.btn.delalldeviceclock': '确定要清除设备中的闹铃吗？',
  'device.device.btn.deldevicedata': '清除设备数据',
  'device.device.btn.conn': '通讯参数设置',
  'device.device.btn.delfinger': '清除设备指纹',
  'device.device.btn.init': '设备初始化',
  'device.device.btn.initconfirm': '确定要将设备恢复到出厂设置状态吗？',
  'device.device.MsgNoReader': '请先插入巡检设备，再进行通信',
  'device.device.MsgNoFount': '未找到巡检设备，请确认是否已连接',
  'device.device.MsgWriteFailed': '写入数据失败',
  'device.device.MsgOpenFailed': '打开设备失败',
  'device.device.MsgReadFailed': '读取设备数据失败.',
  'device.device.MsgConnFailed': '设备连接失败.',
  'device.device.MsgTypeFailed': '设备类别错误',
  'device.device.noparam': '当前接入设备不需要进行参数设置',
  'device.device.init': '确定要将设备恢复到出厂设置吗？',
  'device.system.init': '系统初始化',

  'device.btn.download.all': '下载全部数据',
  'device.btn.download.select': '下载选中数据',

  'device.guardname': '人员显示名称',
  'device.guardname.place': '请输入人员名称',
  'device.guardname.des': '用于显示在液晶屏上的名称',

  'device.clock': '闹铃设置',
  'device.clock.sn': '序号',
  'device.clock.name': '闹铃时间',
  'device.clock.add': '增加闹铃',
  'device.clock.del': '删除闹铃',
  'device.clock.down': '下载闹铃',
  'device.clock.delDown': '删除下载',
  'device.clock.exittime': '输入的闹铃时间已存在，请重新输入',
  'device.clock.nulltime': '请输入闹铃时间',
  'device.clock.max': '闹铃最多只能下载200组，请重新选择',

  'device.flashlight.closemode': '手电关闭方式',
  'device.flashlight.closemode.dafault': '默认',
  'device.flashlight.closemode.20': '点亮20秒自动关闭',
  'device.flashlight.closemode.auto': '自动关闭',
  'device.flashlight.closemode.manual': '手动关闭',
  'device.flashlight.closemode.closetime': '手电关闭时间',

  'device.alarm.title': '报警按键功能',
  'device.alarm.choice1': '按住此键1秒后发送报警信息',
  'device.alarm.choice2': '按住此键2秒后发送报警信息',
  'device.alarm.choice3': '按住此键3秒后发送报警信息',
  'device.alarm.choice4': '按住此键4秒后发送报警信息',
  'device.alarm.choice5': '按住此键5秒后发送报警信息',
  'device.alarm.choice6': '按住此键6秒后发送报警信息',
  'device.alarm.choice7': '按住此键7秒后发送报警信息',
  'device.alarm.choice8': '按住此键8秒后发送报警信息',
  'device.alarm.choice9': '按住此键9秒后发送报警信息',
  'device.alarm.choice10': '按住此键10秒后发送报警信息',
  'device.alarm.choice11': '按住此键 {count} 秒后发送报警信息',
  'device.shuttype': '关机方式',
  'device.shuttype.auto': '自动关机',
  'device.shuttype.manual': '手动关机',
  'device.shuttype.time': '关机时间',
  'device.shuttype.timeandunit': '关机时间（分钟）',
  'device.shuttype.timeandunits': '关机时间（秒）',
  'device.shuttype.autotime': '自动关闭时间',
  'device.shuttype.unit': '单位',
  'device.shuttype.unit.minute': '分钟',
  'device.shuttype.unit.seconds': '秒',

  'device.screen.resolution': '分辨率',
  'device.screen.recordtime': '录像时间（秒）',
  'device.screen.number': '短信中心号码',

  'device.drop.meter1': '一米高度自由落体',
  'device.drop.meter2': '二米高度自由落体',
  'device.drop.meter3': '三米高度自由落体',

  'device.step.state': '计步器状态',
  'device.impact.state': '摔碰报警状态',
  'device.impact.incline': '倾斜报警状态',
  'device.impact.incline.param1': '倾斜报警参数1',
  'device.impact.incline.param2': '倾斜报警参数2',
  'device.impact.incline.param3': '倾斜报警参数3',
  'device.impact.incline.angle': '倾斜报警角度',
  'device.impact.condition': '摔碰报警条件',
  'device.impact.static.param1': '静止报警参数1',
  'device.impact.static.param2': '静止报警参数2',
  'device.impact.static.param3': '静止报警参数3',
  'device.impact.static.sensitivity': '灵敏度',
  'device.static.condition': '静止报警状态',

  'device.clock.func': '闹铃功能',
  'device.clock.h2.ipmode': '通讯模式',
  'device.clock.h2.dynamicip': '动态IP',
  'device.clock.h2.staticip': '静态IP',

  'device.clock.h2.ipaddress': 'IP地址',
  'device.clock.h2.subnet': '子网掩码',
  'device.clock.h2.gateway': '网关',
  'device.clock.h2.wifiname': 'WiFi名称',
  'device.clock.h2.wifipwd': 'WiFi密码',
  'device.clock.h2.wififunc': 'WiFi功能',
  'device.clock.nullwifiname': '请输入WIFI名称',
  'device.clock.nullwifipwd': '请输入WIFI密码',
  'device.clock.h2.interval': '闹铃提醒间隔时间（秒）',
  'device.clock.h2.times': '闹铃提醒次数',
  'device.clock.h2.intervaltimes': '上传时间间隔（秒）',

  'device.call.type': '来电提醒方式',
  'device.call.type.mute': '静音',
  'device.call.type.vibration': '震动',
  'device.call.type.ring': '铃音',
  'device.call.type.both': '震动+铃音',

  'device.voice.state': '语音状态',
  'device.voice.volume': '音量大小',
  'device.voice.volume.high': '高',
  'device.voice.volume.middle': '中',
  'device.voice.volume.low': '低',

  'device.finger.state': '指纹状态',
  'device.finger.verifyone': '有-验证一次',
  'device.finger.verifycard': '有-读卡验证',
  'device.finger.stitching': '指纹拼接次数',
  'device.finger.confirmdel': '确定要删除设备中的指纹数据吗，一旦删除将无法恢复？',
  'device.devicedata.deldatafirst': '删除指纹之前必须先清除设备中数据，删除指纹失败！',
  'device.temperature.display': '温度显示方式',
  'device.temperature.celsius': '摄氏度',
  'device.temperature.fahrenheit': '华氏度',

  'device.gps.working': '工作模式',
  'device.gps.patrol': '巡检模式',
  'device.gps.coll': '采点模式',
  'device.gps.interval': '定位时间间隔（秒）',
  'device.gps.collgps': '采集GPS数据时间（零代表不采集）',

  'device.conn.title': '通讯参数设置',
  'device.conn.type': '连接方式',
  'device.conn.ip': '通过IP地址进行连接',
  'device.conn.domain': '通过域名地址进行连接',
  'device.conn.domain.name': '域名',
  'device.conn.port': '端口号',
  'device.conn.isp': '运营商',
  'device.conn.mobile': '中国移动',
  'device.conn.unicom': '中国联通',
  'device.conn.otherisp': '其他运营商',
  'device.conn.user': '登陆用户',
  'device.conn.pwd': '登陆密码',
  'device.send.mode': '数据上传方式',
  'device.send.auto': '自动发送数据',
  'device.send.manu': '手动发送数据',
  'device.send.func': '发送按键功能',
  'device.send.func1': '按住此键1秒后发送数据信息',
  'device.send.func2': '按住此键2秒后发送数据信息',
  'device.send.func3': '按住此键3秒后发送数据信息',
  'device.send.func4': '按住此键4秒后发送数据信息',
  'device.send.func5': '按住此键5秒后发送数据信息',
  'device.send.func6': '按住此键6秒后发送数据信息',
  'device.send.func7': '按住此键7秒后发送数据信息',
  'device.send.func8': '按住此键8秒后发送数据信息',
  'device.send.func9': '按住此键9秒后发送数据信息',
  'device.send.func10': '按住此键10秒后发送数据信息',
  'device.send.func11': '按住此键 {count} 秒后发送数据信息',

  'device.read.data.loading': '数据上传中，请稍等...',
  'device.read.data.blank': '设备内无数据',
  'device.read.data.success': '数据读取成功，记录数：',
  'device.timing.success': '校时成功，设备号：',
  'device.timing.devicetype': '设备类型：',
  'device.timing.failed': '校时失败',
  'device.read.failed': '读取失败',
  'device.serverTime.error': '获取服务器时间异常',

  'device.status.disconnect': '未检测到设备',
  'device.status.disconnect.desc': '请通过USB连接你手里的设备',

  'device.socket.stop': '通讯组件尚未启动，请确保已启动，尝试刷新界面',
  'device.socket.stop.or': '或',
  'device.socket.download': '下载安装',
  'device.socket.downloading': '浏览器正在下载通讯组件...完成后点击进行手动安装即可 :)',

  'device.socket.exist': '通讯组件已启动',
  'device.socket.exist.desc': '通讯组件已下载并启动，是否仍然继续？',

  'device.socket.busy': '通讯繁忙，请稍后再试...',
  'device.socket.timeout': '通讯超时...',

  'device.register.list': '已注册设备类型',
  'device.register.list.delete': '是否要移除该已注册设备类型？',
  'device.unregister.title': '未注册的设备类型',
  'device.unregister.content': '检测到未注册的设备类型{type}，是否立即注册？',

  'device.register.success': '设备类型注册成功！',

  'device.btn.add': '添加设备',
  'device.btn.active': '激活设备',
  'device.btn.remark': '修改备注',
  'device.btn.param': '远程参数设置',
  'device.btn.home': '返回首页',

  'device.table.device.code': '设备号码',
  'device.table.device.code.des': '请输入设备号码',
  'device.table.device.type': '设备类型',
  'device.table.device.time': '注册时间',
  'device.table.device.account': '账号',
  'device.table.device.name': '姓名',
  'device.table.device.extra': '备注',
  'device.table.device.range': '起止时间',

  'device.table.card.purchase': '购买数量',
  'device.table.card.Remain': '剩余数量',
  'device.table.card.time': '使用期限',
  'device.table.card.purchasetime': '购买时间',
  'device.table.card.year': ' 年',
  'device.table.card.month': ' 个月',
  'device.table.card.date': ' 天',

  'device.modal.remark.title': '备注信息',
  'device.modal.remark.placeholder': '请输入备注信息',
  'device.modal.remark.tip': '如果输入区域名称，则该区域下的操作员都可以查看此设备信息',

  'device.notification.description.add': '设备添加成功',
  'device.notification.description.noneed': '当前选择的设备不需要进行激活操作',
  'device.notification.description.noend':
    '当前选择的设备不需要进行激活操作，请在设备有效期截止前一个月再进行激活设备操作',
  'device.notification.description.type.wrong':
    '选择的设备与当前激活码对应的设备类型不一致，不能完成激活设备操作',
  'device.notification.description.success': '设备激活成功',
  'devidevice.notification.description.typece.notification.description.failed': '设备激活失败',
  'device.notification.description.type':
    '选择的设备与当前接入设备类型不一致，不能完成替换设备操作，请选择相同类型的设备进行替换操作',
  'device.notification.description.code':
    '当前接入的设备与选择的设备号码相同，不能完成替换设备操作，请选择设备号码不同的设备进行替换操作',
  'device.notification.description.confirm':
    '确定要用新设备对原设备进行替换操作吗？替换之后，原设备只能读取替换时间之前的数据，新设备的有效数据将从替换时间之后开始',
  'device.notification.description.unsupport': '当前接入设备不支持此功能',
  'device.notification.description.nosite':
    '不能下载没有巡检地点的计划信息，请先进行巡检地点的设置，然后再下载数据',
  'device.notification.description.noguard':
    '没有可供下载的巡检人员，请先进行巡检人员的设置，然后再下载数据',
  'device.notification.description.noplan':
    '没有可供下载的巡检计划，请先进行巡检计划的设置，然后再下载数据',
  'device.notification.description.clearbook':
    '当前尚未选择任何记录进行下载，如果未选择记录进行下载，将会清除设备中的通讯录信息，是否继续此项操作？',
  'device.notification.description.unselect': '请先勾选要操作的设备信息',

  'device.device.btn.parameterRead': '读取设备参数',
  'device.title.parameter': '设备参数显示',
  'device.card.readInterval': '读卡间隔（秒）',
  'device.flashlight.touchTime': '手电按键时间（秒）',
  'device.send.interval': '自动发送间隔（秒）',
  'device.close.screenTime': '闭屏时间（秒）',
  'device.h2.responseTime': '接收确认时间（秒）',
  'device.new.prompt': '检测到您的通讯组件版本过低，为了不影响正常使用请下载新版通讯组件！（先卸载再安装）',
  'device.input.yes.ipaddress': '请输入正确的IP地址',

  'device.btn.replace': '设备替换',
  'device.table.device.status': '设备状态',
  'device.device.status.using': '使用中',
  'device.device.status.expire': '已过期',
  'device.device.status.replace': '被替换',
};
