// 系统信息，用于统一使用后端返回的标志信息，前端不再单独维护，统一打包产物

import { getFlags } from '@/services/flag';

// 目前包括系列、有无标；
const FlagModel = {
  namespace: 'flag',
  state: {
    // 系列标志
    series: 'cloud',
    // 有无标标志
    model: 'Y',
  },
  effects: {
    *getFlags({ callback }, { call, put }) {
      try {
        const flags = yield call(getFlags);
        yield put({
          type: 'setFlags',
          payload: {
            ...flags,
          },
        });
        callback(true, null);
      } catch (e) {
        callback(false, e);
      }
    },
  },
  reducers: {
    setFlags(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    setSeries(state, { payload }) {
      return {
        ...state,
        series: payload,
      };
    },

    setModel(state, { payload }) {
      return {
        ...state,
        model: payload,
      };
    },
  },
};
export default FlagModel;
