export default {
    'backup.plan.input.placeholder': 'Veuillez entrer le',
    'backup.input.styleErr': 'Erreur de format',
    'backup.plan.title': 'Plan de sauvegarde',
    'backup.plan.add': 'Ajouter un plan de sauvegarde',
    'backup.plan.edit': 'Rédacteur en chef',
    'backup.plan.del': 'Supprimer un plan de sauvegarde',
    'backup.plan.table.name': 'Nom du régime',
    'backup.plan.table.period': 'Type de cycle',
    'backup.plan.table.periodValue': 'Nombre de cycles',
    'backup.plan.table.backupTime': 'Temps de sauvegarde',
    'backup.plan.table.backupPath': 'Chemin de sauvegarde',
    'backup.plan.table.reserveDay': 'Jours de conservation des fichiers',
    'backup.table.operate': 'opérations',
    'backup.plan.input.backupPath.remark': 'Doit être créé à l’avance, si vous n’entrez pas, utilisez l’emplacement par défaut',
    'backup.plan.period.day': 'jours',
    'backup.plan.period.week': 'semaines',
    'backup.plan.period.month': 'le',
    'backup.file.manual': 'Sauvegarde manuelle manuelle',
    'backup.file.upload': 'Importez vos sauvegardes',
    'backup.file.del': 'Supprimer une sauvegarde',
    'backup.file.download': 'Le téléchargement',
    'backup.file.restore': 'La reprise',
    'backup.file.table.fileName': 'Nom du fichier',
    'backup.file.table.datasource': 'Source des données',
    'backup.file.table.createTime': 'Temps de sauvegarde',
    'backup.file.table.fileSize': 'Taille du fichier',
    'backup.file.manual.filePath': 'Chemin de stockage de fichiers',
    'backup.file.table.isSuccess': 'Résultats des opérations',
    'backup.file.isSuccess.ok': 'Le succès',
    'backup.file.isSuccess.fail': 'l’échec',
    'backup.file.restore.warnInfo': 'Important: après la récupération avec ce fichier, le système effacera toutes les données et les restaure à la période correspondante de ce fichier. S’il vous plaît faire attention si vous voulez restaurer?',
    'backup.file.restore.success.msg': '{back} a été rétabli avec succès, veuillez vous reconnecter au système',
    'backup.file.restore.auth': 'Reprise de la vérification opérationnelle',
    'backup.file.restore.authErr': 'La vérification de l’opération de récupération a échoué',
    'backup.file.datasource.task': 'Sauvegardes programmées',
    'backup.file.datasource.manual': 'Sauvegarde manuelle manuelle',
    'backup.file.datasource.import': 'Importez vos sauvegardes',
};