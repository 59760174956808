export default {
  'menu.home': '首页',
  'menu.dashboard': '工作台',

  // menu信息与角色全新树信息由后台的module强关联
  // 所以统一移动至module文件符合开发直觉

  // todo：待改
  'menu.talkback': '对讲管理',
  'menu.talkback.group': '对讲群组管理',
  'menu.talkback.intercom': '对讲语音管理',
  'menu.talkback.record': '对讲记录',

  // 私有化部署使用，系统参数设置里管理，单独控制不走modules
  'menu.data.backupPlan': '数据库备份计划',
  'menu.data.backupFile': '备份文件列表',
};
