export default {
  'user.title': 'Χειριστής',
  'user.btn.add': 'Προσθήκη Χειριστή',
  'user.btn.dept': 'Εμφάνιση Εταιρίας',
  'user.btn.right': 'Εκχώρηση πρόσβασης',
  'user.btn.init': 'Αρχικοποίηση Κωδικού',
  'user.btn.home': 'Επιστροφή σε Αρχική',
  'user.table.displayname': 'Όνομα Σύνδεσης',
  'user.table.username': 'Όνομα χειριστή',
  'user.table.rolename': 'Ρόλος',
  'user.table.operate': 'Χειρισμός',
  'user.table.edit': 'Επεξεργασία',
  'user.role.title': 'Εκχώρηση Πρόσβασης',
  'user.role.label': 'Λίστα Ρόλων',
  'user.role.message': 'Παρακαλώ επιλέξτε ρόλο',
  'user.input.username.placeholder': 'Παρακαλώ εισάγετε όνομα χειριστή',
  'user.input.displayname.placeholder': 'Παρακαλώ εισάγετε Όνομα Σύνδεσης',
  'user.input.email.placeholder': 'Παρακαλώ εισάγετε Email',
  'user.btn.init.confirm': 'θέλετε αρχικοποίηση κωδικού? (Εργοστασιακός κωδικός: 999)',

  'user.serial.title': 'Ενεργοποίηση Συστήματος',
  'user.serial.activation': 'Κωδικός Ενεργοποίησης',
  'user.serial.verification': 'Κωδικός Επιβεβαίωσης',
  'user.serial.verification.placeholder': 'Παρακαλώ εισάγετε κωδικό επιβεβαίωσης',
  'user.app.expand': 'Επέκταση ποσότητας Εφαρμογής',
  'user.app.count': 'Ποσότητα',
};
