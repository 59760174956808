export default {
  'book.title': 'Επαφές',
  'book.btn.add': 'Προσθέστε επαφές',
  'book.btn.import': 'Εισαγωγή Επαφής',
  'book.btn.export': 'Εξαγωγή επαφής',
  'book.table.name': 'Όνομα Επαφής',
  'book.table.tel': 'Τηλέφωνο Επαφής',
  'book.input.name': 'Παρακαλώ εισάγετε όνομα επαφής.',
  'book.input.tel': 'Παρακαώ εισάγετε τηλέφωνο επαφής.',
  'book.table.moveup': 'Μετακινήστε προς τα πάνω',
  'book.table.movedown': 'Κινήστε προς τα κάτω',
};
