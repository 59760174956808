export default {
  'enclosure.title': 'La clôture électronique',
  'enclosure.table.name': 'Nom de clôture',
  'enclosure.table.name.rule': 'Veuillez entrer le nom de la clôture',
  'enclosure.table.timeRange': 'Horizon de temps',
  'enclosure.input.timeRange.add': 'Ajouter un temps',
  'enclosure.btn.add': 'Ajouter une clôture',
  'enclosure.warn.updateAndNotSave':
    'La clôture n’a pas enregistré les paramètres après la modification, est-elle toujours fermée?',
  'enclosure.btn.clear': 'Vider la clôture',
  'enclosure.clear.confirm': 'Sûr de vouloir vider les données de votre clôture?',
  'enclosure.btn.del': 'Supprimer la clôture',
  'enclosure.del.confirm': 'Sûr de vouloir supprimer les données de clôture?',
  'enclosure.btn.currentSite': 'La position actuelle',
  'enclosure.table.btn.fence': 'Les clôtures',
  'enclosure.warn.timeRange.coincide':
    'Les intervalles de temps coïncident, s’il vous plaît réinitialiser',
  'enclosure.warn.timeRange.empty': 'Veuillez sélectionner une heure',
  'enclosure.warn.timeRange.notCheck': 'La période ne peut pas être vide',
};
