export default {
  'exception.fail': 'Αποτυχία αίτησης',
  'exception.unknow': 'Συνέβει άγνωστο σφάλμα',
  'exception.network': 'Σφάλμα δικτύου',
  'exception.network.desc': 'Δεν μπορεί να συνδεθεί στο server',

  'exception.403': 'Λυπούμαστε, δεν έχετε εξουσιοδότηση πρόσβασης σε αυτόν τον πόρο.',
  'exception.404': 'Ωχ..., ο πόρος δεν μπορεί να βρεθεί.',
  'exception.500': 'Ωχ, ο server αντιμετώπισε κάποια μικρά προβλήματα.',

  'exception.400': 'Λάθος αίτημα',
  'exception.401': 'Μη έγκυρα διαπιστευτήρια χρήστη',
  'exception.502': '502',
  'exception.503': '503',
  'exception.504': 'Λήξη χρονικού ορίου προεπ.πύλης',

  'exception.crash': 'Ωχ, Συνέβει κάτι απροσδόκητο...',
  'exception.disconnect': 'Unable to load resources from server...',
};
