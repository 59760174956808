export default {
  'site.title': 'Checkpoint Setup',
  'site.btn.batch': 'Batch Read',
  'site.btn.add': 'Add Checkpoint',
  'site.btn.addgps': 'Add GPS Checkpoint',
  'site.btn.addcoll': 'Add Collection Checkpoint',
  'site.btn.edit': 'Edit',
  'site.btn.del': 'Delete Checkpoint',
  'site.btn.download': 'Download Data',
  'site.btn.import': 'Import Checkpoint',
  'site.btn.export': 'Export Checkpoint',
  'site.btn.assign': 'Assign Content',
  'site.btn.move': 'Transfer Checkpoint',
  'site.btn.home': 'Return Home',
  'site.btn.rnd': 'Randomly assigned checkpoints',
  'site.btn.rnd.del': 'Delete all checkpoints',
  'site.modal.position': 'Geographical Position',
  'site.table.code': 'Checkpoint ID',
  'site.table.name': 'Checkpoint Name',
  'site.table.type': 'Checkpoint Type',
  'site.table.longitude': 'Longitude',
  'site.table.latitude': 'Latitude',
  'site.table.range': 'Error Range (m)',
  'site.table.voice': 'Voice',
  'site.table.site': 'Position',
  'site.table.app.content': 'Content Title',
  'site.table.app.des': 'Content Description',
  'site.table.app.submission': 'Submission Method',
  'site.table.app.qr': 'Qr Code',
  'site.table.app.bluetooth': 'Bluetooth',
  'site.table.app.em': 'EM code',
  'site.table.app.single': 'Single',
  'site.table.app.multiple': 'Multiple',
  'site.table.app.enter': 'Enter',
  'site.table.app.colluser': 'Collectors',
  'site.table.app.colltime': 'Collect time',
  'site.table.voice.clear': 'Clear Voice in Device',
  'site.table.voice.clear.success': 'Voice cleared successfully',
  'site.table.voice.clear.failed': 'Voice cleared failed',
  'site.table.voice.clear.confirm': 'Confirm to delete the Voice ?',
  'site.table.edit': 'Edit',
  'site.input.site.placeholder': 'Please input checkpoint name',
  'site.input.code.placeholder': 'Please input checkpoint ID',
  'site.Transfer.Unselected': 'Unselected Voices',
  'site.Transfer.selected': 'Selected Voices',
  'site.headerTitle.GPS': 'All Gps Checkpoints',
  'site.headerTitle.All': 'List of configured voice and GPS checkpoints',
  'site.notification.filtercode': 'Records with duplicate checkpoint codes have been filtered out',
  'site.notification.filtername': 'Records with duplicate checkpoint names have been filtered out',
  'site.notification.download.success': 'Data download successful',
  'site.notification.mostsite': 'No more than 1000 checkpoints could be selected',
  'site.notification.mostgpssite': 'No more than 500 GPS checkpoints could be selected',
  'site.notification.delposition':
    'Are you sure you want to delete the location coordinates in the map?',
  'site.table.moveup': 'Move Up',
  'site.table.movedown': 'Move Down',
};
