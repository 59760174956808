export default {
  'module.1': 'Настройка обходов',
  'module.101': 'Настройка объектов',
  'module.10101': 'Добавить область',
  'module.10102': 'Редактировать компанию',
  'module.10103': 'Удалить компанию',
  'module.10104': 'Область просмотра',
  'module.102': 'Настройка событий',
  'module.10201': 'Добавить событие',
  'module.10202': 'Изменитьсобытие',
  'module.10203': 'Удалитьсобытие',
  'module.10204': 'Проверь меню.',
  'module.10205': 'Импорт',
  'module.10206': 'Экспорт',
  'module.10207': 'Считать метки',
  'module.103': 'Настройка сотрудников',
  'module.10301': 'Добавить сотрудника',
  'module.10302': 'Изменить сотрудника',
  'module.10303': 'Удалить сотрудника',
  'module.10304': 'Проверь меню.',
  'module.10305': 'Импорт',
  'module.10306': 'Экспорт',
  'module.10307': 'Считать метки',
  'module.10308': 'Собирать отпечатки пальцев',
  'module.10309': 'Отпечатки пальцев.',
  'module.10310': 'Загрузить отпечаток пальца',
  'module.104': 'Настройка меток',
  'module.10401': 'Добавить сайт',
  'module.10402': 'Редактировать сайт',
  'module.10403': 'Удалить сайт',
  'module.10404': 'Проверь меню.',
  'module.10405': 'Импорт сайта',
  'module.10406': 'Загрузка данных',
  'module.10407': 'Считать метки',
  'module.10408': 'Назначить содержимое',
  'module.10409': 'Экспорт сайта',
  'module.105': 'Настройка графиков',
  'module.10501': 'Добавить график',
  'module.10502': 'Изменитьграфик',
  'module.10503': 'Удалитьграфик',
  'module.10504': 'Проверь меню.',
  'module.10505': 'Привязать устройство',
  'module.10506': 'План экспорта',
  'module.106': 'Регистрация оборудования для инспекции',
  'module.10601': 'Регистрация оборудования',
  'module.10602': 'Удалите устройство.',
  'module.10604': 'Проверь меню.',
  'module.107': 'Настройка тел. контактов',
  'module.10701': 'Добавить',
  'module.10702': 'Изменить',
  'module.10703': 'Удалить',
  'module.10704': 'Проверь меню.',
  'module.10706': 'Импорт',
  'module.10705': 'Экспорт',
  'module.108': 'Информация о платежах',
  'module.10801': 'Добавить',
  'module.10802': 'Редактор отвечает на сообщение',
  'module.10803': 'Удалите сообщение',
  'module.10804': 'Проверь меню.',
  'module.10805': 'Импорт',
  'module.10806': 'Экспорт',
  'module.109': 'Загрузка данных',
  'module.10901': 'Загрузка данных',
  'module.10904': 'Проверь меню.',
  'module.110': 'Чек листы',
  'module.11001': 'Добавить',
  'module.11002': 'Изменить',
  'module.11003': 'Удалить',
  'module.11004': 'Проверь меню.',
  'module.11005': 'Присвоить метки',
  'module.111': 'Настройка рации',
  'module.11101': 'Добавить группу',
  'module.11102': 'Изменитьгруппу',
  'module.11103': 'Удалитьгруппу',
  'module.11104': 'Проверь меню.',
  'module.112': 'Голосовые настройки',
  'module.11204': 'Проверь меню.',
  'module.113': 'Загрузка данных',
  'module.11301': 'Загрузка данных',
  'module.11304': 'Проверь меню.',
  'module.114': 'Электронное ограждение',
  'module.11401': 'Новые заборы.',
  'module.11402': 'Ограждение для монтажа',
  'module.11403': 'Удали ограждение.',
  'module.11404': 'Проверь меню.',
  'module.2': 'Основные операции',
  'module.201': 'Прочитать данные',
  'module.20104': 'Прочитать данные',
  'module.202': 'Журнал данных',
  'module.20201': 'Выведите исторические данные',
  'module.20203': 'Повторный анализ',
  'module.20204': 'Проверь меню.',
  'module.203': 'Отчет обходов',
  'module.20301': 'Выведите отчет об инспекции',
  'module.20304': 'Проверь меню.',
  'module.204': 'Статистика',
  'module.20401': 'Выведите статистику',
  'module.20404': 'Проверь меню.',
  'module.205': 'Нарушения обходов',
  'module.20501': 'Выведите данные об утечке',
  'module.20504': 'Проверь меню.',
  'module.206': 'Тревоги',
  'module.20601': 'Выведите данные по сигнализации',
  'module.20603': 'Удалите данные.',
  'module.20604': 'Проверь меню.',
  'module.207': 'Сис. журнал',
  'module.20701': 'Выведите данные из журнала',
  'module.20704': 'Проверь меню.',
  'module.208': 'Удалить данные устройства',
  'module.20804': 'Удалить данные устройства',
  'module.209': 'Время устройства',
  'module.20904': 'Время устройства',
  'module.210': 'Удалить отпечаток пальца устройства',
  'module.21004': 'Удалить отпечаток пальца устройства',
  'module.211': 'Мониторинг в реальном времени',
  'module.21101': 'Перекличка редактора',
  'module.21102': 'Отслеживать воспроизведение',
  'module.21104': 'Проверь меню.',
  'module.21105': 'Проверьте данные в реальном времени.',
  'module.212': 'Исходные данные',
  'module.21201': 'Выведите оригинальные данные',
  'module.21202': 'Неизвестная конверсия карты',
  'module.21204': 'Проверь меню.',
  'module.213': 'Календарь обходов',
  'module.21304': 'Проверь меню.',
  'module.214': 'Данные о прибытиях',
  'module.21401': 'Экспортируйте данные посещаемости',
  'module.21404': 'Проверь меню.',
  'module.215': 'Детали осмотра',
  'module.21501': 'Выведите инспекцию',
  'module.21504': 'Проверь меню.',
  'module.3': 'Системные настройки',
  'module.302': 'Список устройств',
  'module.30201': 'Активировать устройство',
  'module.30202': 'Добавить устройство',
  'module.30203': 'Удалите устройство.',
  'module.30204': 'Проверь меню.',
  'module.30205': 'Заменить устройство',
  'module.30206': 'Настройка параметров',
  'module.30207': 'Редактировать примечание',
  'module.303': 'Системные настройки',
  'module.30304': 'Системные настройки',
  'module.304': 'Настройки устройства',
  'module.30404': 'Проверьте параметры оборудования',
  'module.30408': 'Настройка будильника',
  'module.305': 'Параметры связи. установка программы',
  'module.30504': 'Проверьте параметры связи',
  'module.306': 'Настройка ролей',
  'module.30601': 'Добавить роль',
  'module.30602': 'Изменитьроль',
  'module.30603': 'Удалитьроль',
  'module.30604': 'Проверь меню.',
  'module.307': 'Смена пароля',
  'module.30701': 'Добавить оператора',
  'module.30702': 'Редактированиепользователя',
  'module.30703': 'Удалитьпользователь',
  'module.30704': 'Проверь меню.',
  'module.30705': 'Область просмотра',
  'module.30706': 'Правильное назначение',
  'module.30708': 'Изменить пароль',
  'module.308': 'Инициализация устройства',
  'module.30804': 'Инициализация устройства',
  'module.5': 'Панель управления',
  'module.216': 'BI',
  'module.21604': 'Проверь меню.',
};
