export default {
  'comm.1': 'Текущее устройство доступа не поддерживает эту функцию',
  'comm.4096': 'Неизвестное устройство',
  'comm.4097': 'Устройство не обнаружено',
  'comm.4098': 'Ошибка записи',
  'comm.4099': 'Не удалось прочитать устройство',
  'comm.4100': 'Не удалось открыть устройство',
  'comm.4101': 'Ошибка соединения',
  'comm.4102': 'Ошибка класса устройства',
  'comm.4104': 'Имя файла пусто',
  'comm.4105': 'Не удалось создать файл',
  'comm.4113': 'Ошибка инициализации',
  'comm.4150': 'База данных заполнена, пожалуйста, удалите отпечаток пальца перед его сбором',
  'comm.4500': 'Сначала настройте голос и создайте файл голосовой библиотеки',
};
