export default {
  'comm.1': '当前接入设备不支持此功能',
  'comm.4096': '未知设备',
  'comm.4097': '未检测到设备',
  'comm.4098': '设备写入数据失败',
  'comm.4099': '读取设备失败',
  'comm.4100': '打开设备失败',
  'comm.4101': '连接失败',
  'comm.4102': '设备类别错误',
  'comm.4104': '文件名称为空',
  'comm.4105': '创建文件失败',
  'comm.4113': '初始化失败',
  'comm.4150': '数据库已满，请删除指纹后再进行采集',
  'comm.4500': '请先配置语音并生成语音库文件',
};
