export default {
    'backup.plan.input.placeholder': 'Входите.',
    'backup.input.styleErr': 'Ошибка формата.',
    'backup.plan.title': 'Запасной план.',
    'backup.plan.add': 'Увеличить резервный план.',
    'backup.plan.edit': 'редактор',
    'backup.plan.del': 'Удали запасной план.',
    'backup.plan.table.name': 'Плановое имя',
    'backup.plan.table.period': 'Периодический тип',
    'backup.plan.table.periodValue': 'Периодическое число',
    'backup.plan.table.backupTime': 'Время резервного копирования.',
    'backup.plan.table.backupPath': 'Запасной путь.',
    'backup.plan.table.reserveDay': 'Документы хранятся несколько дней',
    'backup.table.operate': 'операцион',
    'backup.plan.input.backupPath.remark': 'Необходимо создавать заранее, а не вводиться по умолчанию',
    'backup.plan.period.day': 'дне',
    'backup.plan.period.week': 'недел',
    'backup.plan.period.month': 'месяц',
    'backup.file.manual': 'Ручное резервное копирование',
    'backup.file.upload': 'Импортная резервная копия',
    'backup.file.del': 'Удалите резервные копии.',
    'backup.file.download': 'загрузк',
    'backup.file.restore': 'восстанов',
    'backup.file.table.fileName': 'Имя файла',
    'backup.file.table.datasource': 'Источник данных',
    'backup.file.table.createTime': 'Время резервного копирования.',
    'backup.file.table.fileSize': 'Размер файла.',
    'backup.file.manual.filePath': 'Путь памяти файла',
    'backup.file.table.isSuccess': 'Результаты операции.',
    'backup.file.isSuccess.ok': 'успешн',
    'backup.file.isSuccess.fail': 'неудач',
    'backup.file.restore.warnInfo': 'Важный совет: после восстановления файла система удалит все данные и восстановит их к периоду, соответствующему этому файлу.',
    'backup.file.restore.success.msg': '{back} Восстановление успешно',
    'backup.file.restore.auth': 'Восстановление подтверждения операции',
    'backup.file.restore.authErr': 'Восстановление оперативной проверки провалилось',
    'backup.file.datasource.task': 'Резервное копирование по времени',
    'backup.file.datasource.manual': 'Ручное резервное копирование',
    'backup.file.datasource.import': 'Импортная резервная копия',
};