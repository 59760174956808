import request from '@/utils/request';
import { stringify } from 'qs';

export async function login(params) {
  return request(`/api/token?${stringify(params)}`, {
    method: 'POST',
  });
}
export async function userLoginOut() {
  return request(`/api/login/out`, {
    method: 'POST',
  });
}
// 用于注册/删除设备后，重新取得用户的设备及权限信息
export async function reloadAuth() {
  return request('/api/userRefresh');
}
export async function getApplication(params) {
  return request(`/api/regist/application?${stringify(params)}`, {
    method: 'POST',
  });
}

export async function getFakeCaptcha(mobile) {
  return request(`/api/regist/captcha?mobile=${mobile}`);
}

export async function getCaptcha(params) {
  return request(`/api/regist/captcha?${stringify(params)}`);
}

export async function getCaptchaImg(params) {
  return request(`/api/login/captcha?${stringify(params)}`);
}

export async function getEmail(params) {
  return request(`/api/regist/email?${stringify(params)}`);
}

// TODO 上线后需要配置到nginx里
export async function getIP() {
  return 'https://api.ipify.org/ip?format=json';
  // return request(`/ip?format=json`);
}

export async function getCity() {
  return request(`/location/ip?ak=ia6HfFL660Bvh43exmH9LrI6`);
}

export async function resetPwdAndSendEmail(companyCode, userName) {
  return request(`/api//login/pwd/${companyCode}/${userName}`, {
    method: 'POST',
  });
}
