export default {
  confirm: 'Confirm',
  cancel: 'Cancel',
  back: 'Back',
  'general.homeSwitch.title.old': "Don't like the new Dashboard? switch to the old one.",
  'general.homeSwitch.title.new': 'Try the new designed Dashboard?',
  'general.WePatrol.qr.download.text': 'Scan to download WePatrol',
  'general.WePatrol.qr.use.text': 'Use [ {code} ] as Register Code to register and login',
  'general.WePatrol.qr.login.text': 'Scan to register and login',
  'general.guest.alert': 'Guest mode showing randomly-generated mock data for reference only...',
  'general.unhandled.alert': 'There are {count} record(s) pending for processing...',
};
