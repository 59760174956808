export default {
  'header.guest': 'Гость',
  'header.welcome': 'Привет, добро пожаловать',
  'header.modifyPassword': 'Изменить пароль',
  'header.logout': 'Выйти',
  'header.read': 'Читать данные',
  'header.timing': 'Время устройства',
  'header.report': 'Отчет об инспекции',
  'header.history': 'История данных',
  'header.chart': 'Статистическая диаграмма',
  'header.fullScreen': 'Включить полноэкранный режим',
  'header.theme': 'Переключить тему',
  'header.theme.light': 'Светлая тема',
  'header.theme.dark': 'Темная тема',
  'header.component.download': 'Компонент связи',

  'header.password.original': 'Исходный пароль',
  'header.password.original.placeholder': 'Пожалуйста, введите исходный пароль',
  'header.password.new': 'Новый пароль',
  'header.password.new.placeholder': 'Пожалуйста, введите новый пароль',
  'header.password.confirm': 'Подтвердите пароль',
  'header.password.confirm.placeholder': 'Пожалуйста, введите пароль для подтверждения',
  'header.password.notMatch': 'Два пароля разные, пожалуйста, подтвердите',
  'header.password.same': 'Исходный пароль такой же, как и новый пароль, менять пароль не нужно',
  'header.password.blank': 'Пароль не может быть пустым',

  'header.password.modify.success':
    'Пароль успешно изменен, через 5 секунд будет выполнен повторный вход в систему',

  'header.device.connected': 'Устройство подключено',
  'header.device.disconnected': 'Устройство не подключено',
  'header.modifyEmail': 'Изменить почтовый ящик',
};
