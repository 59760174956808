export default {
  'content.title': 'Configuration du contenu',
  'content.btn.add': 'Ajouter du contenu',
  'content.btn.site': 'Sélectionnez le point de contrôle',
  'content.btn.choice': 'Ajouter des optionsv',
  'content.label.choice': 'Options de contenu',
  'content.input.content.placeholder': 'Veuillez entrer le contenu',
  'content.input.des.placeholder': 'Veuillez entrer la description du contenu',
  'content.input.choice.placeholder': 'Veuillez saisir les options de contenu',
  'content.notification.description':
    'Le titre du contenu Patrol existe déjà, veuillez le saisir à nouveau.',
  'content.notification.choice.blank':
    'Les options de contenu doivent être saisies lorsque la méthode de soumission est une ou plusieurs options.',
  'content.table.moveup': 'Vers le haut',
  'content.table.movedown': 'Déplacer vers le bas',
};
