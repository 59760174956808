export default {
  // 表格
  'company.table.index': 'Non',
  'company.table.buy.count': "Quantité d'achat",
  'company.table.remain.count': 'Quantité restante',
  'company.table.term': 'Utiliser le temps',
  'company.table.buy.time': "temps d'achat",
  'company.table.status': 'État',
  'company.table.device.type': "Type d'appareil",
  'company.table.operate': 'Fonctionner',
  'company.table.name': "Nom de l'entreprise",
  'company.table.code': "Code de l'entreprise",
  'company.table.software.version': 'Une version de logiciel',
  'company.table.begin.date': 'Heure de début',
  'company.table.end.date': 'Heure de fin',
  'company.table.sales.man': 'vendeur',
  'company.table.device.model': "Modèle d'appareil",
  'company.table.device.count': "Nombre d'appareils",
  'company.table.expire.query': "Requête d'expiration",
  'company.table.date.filer': 'Filtrage des dates',
  // 企业
  'company.info.list': 'Liste des entreprises',
  'company.save': 'Sauver',
  'company.yes': 'Oui',
  'company.no': 'Non',
  'company.edit': 'Modifier',
  'company.version.all': 'Toutes les versions',
  'company.version.formal': 'édition formelle',
  'company.version.try': "version d'essai",
  'company.version.demo': 'version de démonstration',
  'company.version.network': "Version d'enregistrement en ligne",
  'company.version.free': 'Version gratuite',
  'company.all': 'Toutes les entreprises',
  'company.soon.expire': 'entreprise expirant',
  'company.already.expire': 'Entreprise expirée',
  'company.adding': 'Ajouter...',
  'company.add.fail': "Échec de l'ajout de l'établissement",
  'company.changing': 'Édition...',
  'company.change.fail': "Modifier l'échec de l'entreprise",
  'company.btn.add': 'Ajouter une entreprise',
  'company.btn.card': 'Informations sur la carte de recharge',
  'comany.btn.delete': "Supprimer l'entreprise",
  'company.delete.tips': "Assurez-vous de supprimer l'enregistrement?",
  'company.reset.password': 'réinitialiser le mot de passe',
  'company.reset.password.select':
    "Veuillez d'abord sélectionner l'entreprise pour laquelle vous souhaitez réinitialiser votre mot de passe",
  'company.reset.password.success': 'Réinitialisation du mot de passe réussie',
  'company.renewal.customer': 'Renouvellement des informations client',
  'company.register.device.all': "Toutes les informations sur l'appareil enregistré",
  'company.register.device.soon': "Informations sur l'appareil expirant",
  'company.register.device.expire': "Informations sur l'appareil expiré",
  'company.register.company.count': "Le nombre actuel d'entreprises enregistrées",
  'company.register.soon.count': 'Renouvellement des informations client',
  'company.register.expire.count': "Nombre d'entreprises qui ont expiré",
  'company.register.device.all.count': "Nombre actuel d'appareils enregistrés",
  'company.register.device.soon.count': "Nombre d'appareils arrivant à expiration",
  'company.register.device.expire.count': "Nombre d'appareils arrivés à expiration",
  'company.search': 'Recherche',
  'company.reset': 'Réinitialiser',
  'company.filter': 'Filtre',
  'company.recharge.device.count': "Nombre d'appareils de recharge",
  'company.renewal.time': 'Délai de renouvellement',
  'company.renewal.duration': 'Délai de renouvellement',
  // 充值卡
  'company.card.day': 'Jour',
  'company.card.month': 'Mois',
  'company.card.add.month': 'Mois',
  'company.card.year': 'An',
  'company.card.stop': 'désactivé',
  'company.card.start': 'Permettre',
  'company.card.delete.fail': 'La carte de recharge a été utilisée et ne peut pas être supprimée !',
  'company.card.delete.tips':
    'Voulez-vous vraiment supprimer les informations de la carte de recharge sélectionnée ?',
  'company.card.add.fail': "Échec de l'ajout de la carte de recharge",
  'company.card.info.setting': 'Paramètres des informations de la carte de recharge',
  'company.card.pls.buy.count': "Veuillez entrer la quantité d'achat",
  'company.card.pls.term': "Veuillez entrer la date d'expiration",
  'company.card.term.unit': 'unité terminologique',
  'company.card.select.sale.device.model': 'Veuillez sélectionner le mode périphérique',
  'company.card.device.model.not': '企业设备型号为空，无法添加充值卡信息！',
  // 统计
  'company.device.type.statistics': "Statistiques sur les types d'appareils",
  'company.sales.man.statistics': 'Statistiques des vendeurs',
  'company.sales.device.statistics': "Ajouter des statistiques sur l'appareil",
  'company.sales.device.count.statistics':
    "Statistiques sur le nombre d'équipements vendus par mois",
  'company.sales.device.count': "Nombre d'appareils vendus",
  'company.new.customers.statistics': 'Ajouter des statistiques clients',
  'company.customers.register.count': "Nombre d'inscriptions clients",
  'company.new.customers.info.statistics':
    "Ajouter des statistiques d'informations sur les clients",
  'company.system.access.statistics': "Enregistrements d'accès au système",
  'company.sales.man.info.statistics': 'Statistiques des informations sur les vendeurs',
  'company.date': 'Date',
  'company.login.count': 'Heures de connexion',
  'company.one.month.login.count': 'Statistiques de fréquence de connexion au système',
  // 新增企业
  'company.add.set': "Cadre de l'entreprise",
  'company.add.basics': 'Informations de base',
  'company.add.pls.model': "Entrez le modèle de l'appareil",
  'company.add.module.share':
    "L'équipement du trésor d'inspection ne peut pas être partagé avec d'autres équipements !",
  'company.add.country.code.fail':
    'Le code pays a été saisi de manière incorrecte, veuillez réessayer !',
  'company.add.pls.name': "Entrez le nom de l'entreprise",
  'company.add.pls.code': "Le code d'entreprise est requis",
  'company.add.wrongful.code': 'Illegal company code',
  'company.add.code.length':
    "La longueur du code d'entreprise ne peut pas être inférieure à quatre chiffres",
  'company.add.contact': 'Contacter',
  'company.add.pls.contact': "Contact d'entrée",
  'company.add.contact.tel': 'Téléphoner',
  'company.add.pls.contact.tel': "Téléphone d'entrée",
  'company.add.email': 'E-mail',
  'company.add.pls.email': "E-mail d'entrée",
  'company.add.bengin.time': 'Heure de début',
  'company.add.end.time': 'Heure de fin',
  'company.add.province': 'Province',
  'company.add.city': 'Ville',
  'company.add.pls.city': "Ville d'entrée",
  'company.add.register.time': "Heure d'inscription",
  'company.add.sale.device.model': "Modalité de l'appareil",
  'company.add.phone.app.login': 'Téléphone mobile app login',
  'company.add.configure': 'Informations de configuration',
  'company.add.read.type': 'Mode de lecture des données',
  'company.add.omit.push.func': 'Fonction poussoir',
  'company.add.create.user': "Personnel d'ouverture",
  'company.add.time.zone': 'Fuseau horaire',
  'company.add.time.zone.des': 'Veuillez respecter votre fuseau horaire local',
  'company.add.country.code': 'Code postal',
  'company.add.country.app': "Nombre maximum d'utilisateurs mobiles",
  'company.add.pls.country.code': 'Saisir le code pays',
  'company.add.show.device.model': "Modèle d'appareil d'affichage",
  'company.add.no.show.device.model': "Ne pas afficher le modèle de l'appareil",
  'company.add.data.package': 'Mode paquet',
  'company.add.base64.mode': 'ModeBase64',
  'company.add.no.app.push.func': "Pas de fonction push d'application",
  'company.add.app.push.func': "Avec fonction push d'application",
  'company.add.charge.or.not': 'Charger ou pas',
  'company.add.no.charge': 'Gratuit',
  'company.add.charge': 'Charger',
  'company.add.companycode.info':
    "Impossible d'utiliser l'invité ou l'Administrateur comme Code d'entreprise, veuillez saisir à nouveau!",
  // 基础数据信息
  'company.basics.info': 'Donnee de base',
  'company.basics.dept.name': 'Nom du service',
  'company.basics.site.list': 'Liste des points de contrôle',
  'company.basics.site.name': 'Nom du point de contrôle',
  'company.basics.site.code': 'ID de point de contrôle',
  'company.basics.site.delete.tips': "Assurez-vous de supprimer l'enregistrement ?",
  'company.basics.user.list': 'Liste de garde',
  'company.basics.user.name': 'Nom du garde',
  'company.basics.user.code': 'ID de garde',
  'company.basics.user.delete.tips': "Assurez-vous de supprimer l'enregistrement ?",
  'company.basics.event.list': 'Liste des événements',
  'company.basics.event.name': "Nom de l'événement",
  'company.basics.event.code': "ID d'événement",
  'company.basics.user.event.tips': "Assurez-vous de supprimer l'enregistrement ?",
  // 设备
  'company.device.change.end.time.success': "Modifier l'heure de fin de l'appareil avec succès",
  'company.device.number': 'Code appareil',
  'company.device.end.time': 'Heure de fin',
  'company.device.belonging.enterprise': 'Société',
  'company.device.delete.info.tips': "Assurez-vous de supprimer l'enregistrement ?",
  'company.device.change.end.time.tips':
    "Voulez-vous vraiment modifier l'heure de fin de l'appareil enregistré ?",
  'company.device.register.count': "Nombre d'appareils enregistrés",
  'company.device.register.info.statistics': 'Statistiques des appareils enregistrés',
};
