export default {
  'system.title': 'Настройки системных параметров',
  'system.event': 'Функция события',
  'system.event.des': 'Поддерживается ли функция события',
  'system.plan': 'повременный план',
  'system.plan.des':
    'Планы, которые могут выполняться периодически, например, ежедневное, еженедельное, ежемесячное выполнение',
  'system.patroltime': 'Время патрулирования',
  'system.patroltime.des':
    'Отображать ли общее запланированное время осмотра за круг в отчете об осмотре',
  'system.remaintime': 'Оставшееся время',
  'system.remaintime.des':
    'Отображать ли общее запланированное время проверки на круг в отчете об инспекции',
  'system.Sequence': 'Проверка последовательности',
  'system.Sequence.des':
    'Указывает ли он, что каждая точка проверки в плане должна быть проверена в порядке, установленном в плане',
  'system.timeformat': 'Формат даты',
  'system.timeformate.des': 'Формат даты, отображаемый системой',
  'system.dst': 'Летнее время',
  'system.dst.des': 'Переход на летнее время на текущее предприятие или нет',
  'system.dst.preview': 'Переход на летнее время: время перехода на летнее время',
  'system.dst.beginMonth': 'Месяц.',
  'system.dst.endMonth': 'Конец месяца',
  'system.dst.spanHour': 'Ширина (час)',
  'system.dst.weekVal': 'недел',
  'system.dst.weekIndex': 'No.{indexVal}',
  'system.dst.dataNullErrorMsg': 'Переход на летнее время не установлен',
  'system.dst.beginMonthGeEndError': 'Начало месяца должно быть меньше, чем конец месяца',
  'system.dst.weekIndex.tooltip':
    'Если в течение месяца не будет указанного числа недель, то процедура будет подсчитана в соответствии с назначенной неделей последней недели.',
  'system.database.period': 'цикл резервного копирования баз данных',
  'system.database.period.des': 'частота резервного копирования базы данных: день',
  'system.database.time': 'время резервного копирования базы данных',
  'system.database.time.des': 'Попробуйте выбрать время с низкой пиковой отдачей данных',
  'system.database.path': 'путь к Резервному копированию баз данных',
  'system.database.path.des':
    'Введите путь к Резервному копированию базы данных, например: d:\backup. если путь пуст, база данных не будет резервной',
  'system.save': 'Сохранить настройки',
  'system.enable': 'Включить',
  'system.disable': 'Отключить',
  'system.notification.prompt': 'Подсказка',
  'system.notification.success': 'Сохранить успешно! ',
  'system.deptBaseDataCalMode.title': 'Способ вычисления региональных базовых данных',
  'system.deptBaseDataCalMode.desc':
    'Обзоры устанавливают интерфейс управления базовыми данными, способ вычисления числа типов подлежащих узлам в форме дерева в регионе',
  'system.deptBaseDataCalMode.options.currentDept': 'Рассчитайте только текущую область',
  'system.deptBaseDataCalMode.options.childrenDept':
    'Рассчитайте все текущие регионы и нижние уровни',
  'system.lowAutoSendInterval.title':
    'Время между сигнализацией о низкой мощности (единица: минута)',
  'system.lowAutoSendInterval.desc':
    'Когда устройство настроено на низковольтную сигнализацию, перенаправляйте данные и напомните пользователю о временном интервале',
  'system.realTimeMonitorModule.title': 'Режим мониторинга в реальном времени',
  'system.realTimeMonitorModule.desc':
    'Установление реального времени наблюдения за использованием карт или схем, также связано с тем, работает ли ограждение электронов',
  'system.realTimeMonitorModule.options.map': 'Онлайн карта',
  'system.realTimeMonitorModule.options.imgFile': 'Схема.',
  'system.compantTimezoneUpt.syncMsg':
    'Изменения часовых поясов по умолчанию происходят в корпоративных зонах, синхронизируются ли они с неизменяемыми зонами времени',
  'system.timezone.des':
    'По умолчанию временная зона нынешнего предприятия применяется в основном к размещению часовых поясов региона, а также к функции часовых поясов в таких областях, как школа оборудования',
  'system.deptTimezone.title': 'Временная зона установлена',
  'system.deptTimezone.des': 'Поддерживается ли зона, которая сама определяет свою часовую зону, и после открытия она также позволит оборудованию самостоятельно управлять часовым поясом, который после закрытия будет восстановлен как корпоративный часовой пояс.',
};
