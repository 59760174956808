export default {
  'talk.title': '对讲组',
  'talk.btn.add': '新增组',
  'talk.btn.guard': '下载人员',
  'talk.table.name': '组名称',
  'talk.input.name': '请输入对讲组名称',
  'talk.download.nodata': '没有要下载的设备信息，请先录入设备再下载数据',
  'talk.btn.groupUrl': '服务设置',
  'talk.input.groupUrlData': '通讯地址信息',
};
