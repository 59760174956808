export default {
  'sound.title': 'Configuration vocale',
  'sound.no': 'Non.',
  'sound.name': 'Contenu vocal',
  'sound.name.placeholder': 'Veuillez saisir le contenu vocal',
  'sound.dept.placeholder': 'Veuillez sélectionner une entreprise',
  'sound.table.operate': 'Opérations',
  'sound.table.edit': 'Éditer',
  'sound.table.add': 'Ajouter une voix',
  'sound.table.home': 'Rentrer à la maison',
  'sound.table.voice': 'Générer de la voix',
  'sound.table.clear': "Effacer la voix de l'appareil",
  'sound.table.voice.success': 'Voix générée avec succès!',
  'sound.table.voice.err': 'La voix générée a échoué',
  'sound.table.delete': 'Supprimer',
  'sound.table.selected': 'Choisie',
  'sound.table.item': 'Objet',
  'sound.modal.delconfirm': 'Êtes-vous sûr de vouloir supprimer les données sélectionnées?',
  'sound.table.deletesuccess': 'Supprimé avec succès',
  'sound.modal.ok': 'Sauver',
  'sound.modal.cancel': 'Annuler',
  'sound.modal.error': "L'opération a échoué",
  'sound.message.loading.add': 'Ajouter...',
  'sound.message.loading.edit': 'Configuration...',
  'sound.genvoice.loading': 'Chargement en cours...',
  'sound.genvoice.invalidDevice': "L'appareil actuel ne prend pas en charge la voix...",
};
