export default {
  'system.title': 'Configuration des paramètres système',
  'system.event': 'Fonction événement',
  'system.event.des': "La fonction d'événement est-elle prise en charge",
  'system.plan': 'Régime spécial',
  'system.plan.des':
    'Un plan qui peut être exécuté périodiquement, tel que quotidien, hebdomadaire, mensuel',
  'system.patroltime': 'Temps de patrouille',
  'system.patroltime.des':
    'Si le temps de patrouille total prévu de chaque cercle est affiché dans le rapport de patrouille',
  'system.remaintime': 'Temps restant',
  'system.remaintime.des':
    "Afficher l'heure d'inspection du même point d'inspection dans la période de temps planifiée dans le rapport d'inspection",
  'system.Sequence': 'Patrouille de séquence',
  'system.Sequence.des':
    "S'il faut spécifier que chaque point de contrôle du plan doit être inspecté dans l'ordre défini dans le plan",
  'system.dst': "Heure d'été",
  'system.dst.des': 'Si le mode DST est activé pour l’entreprise actuelle',
  'system.dst.preview': 'Période d’heure d’été: {beginTime} ~ {endTime}, durée: {spanHour} heures',
  'system.dst.beginMonth': 'Mois de début',
  'system.dst.endMonth': 'Mois de fin',
  'system.dst.spanHour': 'Durée en heures',
  'system.dst.weekVal': 'semaine',
  'system.dst.weekIndex': 'Numéro de {indexVal}',
  'system.dst.dataNullErrorMsg': 'Heure d’été non configurée, modifier',
  'system.dst.beginMonthGeEndError': 'Le mois de début doit être inférieur au mois de fin',
  'system.dst.weekIndex.tooltip':
    'S’il n’y a pas de nombre de semaines dans le mois pour le nombre spécifié, le programme sera calculé en fonction de la semaine spécifiée de la dernière semaine.',
  'system.timeformate': 'Format de date',
  'system.timeformate.des': 'Format de date affiché par le système',
  'system.database.period': 'Cycle de sauvegarde de la base de données',
  'system.database.period.des': 'Fréquence des sauvegardes de la base de données en jours',
  'system.database.time': 'Temps de sauvegarde de la base de données',
  'system.database.time.des':
    'Veuillez essayer de sélectionner le temps de pointe faible pour le téléchargement des données',
  'system.database.path': 'Chemin de sauvegarde de la base de données',
  'system.database.path.des':
    "Saisissez le chemin de sauvegarde de la base de données, par exemple: D:\backup. Si le chemin est vide, la base de données n'est pas sauvegardée",
  'system.save': 'Sauver',
  'system.enable': 'Permettre',
  'system.disable': 'Désactiver',
  'system.notification.prompt': 'Rapide',
  'system.notification.success': 'Sauvegarde réussie',
  'system.deptBaseDataCalMode.title': 'Mode de calcul des données de base régionales',
  'system.deptBaseDataCalMode.desc':
    'Interface de gestion des données de chaque base sous les paramètres d’inspection, mode de calcul du nombre de types de base sous les noeuds de l’arborescence régionale',
  'system.deptBaseDataCalMode.options.currentDept': 'Calculer uniquement la région actuelle',
  'system.deptBaseDataCalMode.options.childrenDept': 'Calculer la région actuelle sous-niveau tous',
  'system.lowAutoSendInterval.title':
    'Temps d’intervalle d’alarme de batterie faible (unité: minutes)',
  'system.lowAutoSendInterval.desc':
    'Placez l’intervalle de temps pour pousser les données et rappeler l’utilisateur quand le dispositif alarme de batterie faible',
  'system.realTimeMonitorModule.title': 'Mode de surveillance temps réel',
  'system.realTimeMonitorModule.desc':
    'Mise en place de la surveillance en temps réel utilisant des cartes ou des schémas, également associés influent sur la clôture électronique ou non en vigueur',
  'system.realTimeMonitorModule.options.map': 'Carte en ligne',
  'system.realTimeMonitorModule.options.imgFile': 'Schéma de',
  'system.compantTimezoneUpt.syncMsg':
    'Le fuseau horaire par défaut de l’entreprise a été modifié, synchronisé ou non avec le fuseau horaire régional inchangé',
  'system.timezone.des':
    'Le fuseau horaire par défaut de l’entreprise actuelle, principalement appliqué à la configuration du fuseau horaire de la région, et le temps d’école de l’équipement et d’autres fonctions impliquées dans le fuseau horaire',
  'system.deptTimezone.title': 'Réglage du fuseau horaire régional',
  'system.deptTimezone.des': 'Si la personnalisation de la région de soutien pour ajuster le fuseau horaire, après l’ouverture permettra également à l’appareil de gérer son propre fuseau horaire, après la fermeture pour restaurer le fuseau horaire de l’entreprise.',
};
