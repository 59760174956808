export default {
  'plan.title': 'Plan Setup',
  'plan.title.special': 'Special Plan Setup',
  'plan.btn.add': 'Add Plan',
  'plan.btn.addTemporary': 'Add Temporary Plan',
  'plan.btn.bind': 'Assign Device',
  'plan.btn.copy': 'Copy Plan',
  'plan.btn.paste': 'Paste Plan',
  'plan.btn.export': 'Export Plan',
  'plan.btn.batch': 'Batch Schedule',
  'plan.btn.addshift': 'Add Schedule',
  'plan.btn.delshift': 'Delete Schedule',
  'plan.btn.guard': 'Guard Setup',
  'plan.btn.addrest': 'Add Rest',
  'plan.btn.Manu': 'Assign Manually',
  'plan.modal.label.next': 'The next day',
  'plan.modal.label.onetime': 'Time for Patrol One Time (minutes)',
  'plan.modal.label.resttime': 'Rest Time (minutes)',
  'plan.modal.label.no': 'No ',
  'plan.modal.label.day': ' day',
  'plan.modal.label.weekrest': 'Week Rest',
  'plan.modal.label.specialrest': 'Special Rest',
  'plan.modal.label.period': 'Patrol Period',
  'plan.modal.label.unit': 'Unit',
  'plan.modal.label.plantimes': 'Plan Times',
  'plan.modal.label.times': 'Times',
  'plan.modal.label.cost': 'Time for Patrol One Round',
  'plan.modal.label.interval': 'Two Rounds Interval',
  'plan.modal.label.month': 'Month',
  'plan.modal.label.week': 'Week',
  'plan.modal.label.hour': 'Hour',
  'plan.modal.label.minute': 'Minute',
  'plan.modal.label.days': 'Day',
  'plan.table.schedule': 'Schedule',
  'plan.table.restday': 'Rest',
  'plan.table.monday': 'Monday',
  'plan.table.tuesday': 'Tuesday',
  'plan.table.wednesday': 'Wednesday',
  'plan.table.thursday': 'Thursday',
  'plan.table.friday': 'Friday',
  'plan.table.saturday': 'Saturday',
  'plan.table.sunday': 'Sunday',
  'plan.table.type': 'Plan Type',
  'plan.table.per': 'Per ',
  'plan.table.month': ' month',
  'plan.table.patrol': ' patrol',
  'plan.tab.plan': 'Plan',
  'plan.tab.special': 'Special Plan',
  'plan.tab.schedule': 'Patrol Schedule',
  'plan.step.basic': 'Basic Settings',
  'plan.step.site': 'Checkpoint Setup',
  'plan.step.shift': 'Schedule Setup',
  'plan.step.next': 'Next',
  'plan.step.prev': 'Prev',
  'plan.step.submit': 'Submit',
  'plan.step.rest': 'Restday Setup',
  'plan.transfer.site.select': 'Selected Checkpoints',
  'plan.transfer.site.unselect': 'Not Selected Checkpoints',
  'plan.input.plan.placeholder': 'Please input plan name',
  'plan.notification.description.save.success': 'Save plan successfully',
  'plan.notification.description.sitefirst': 'Please set the checkpoints first',
  'plan.notification.description.schedulefirst': 'Please set the schedules first',
  'plan.notification.description.exitplan':
    'The data of this day has been created in this schedule.Need to replace the data and recreat new data ?',
  'plan.notification.description.than24': 'Each patrol time should be within 24 hours.',
  'plan.notification.description.repeat': 'Time repeated',
  'plan.notification.description.date': 'The end time must be greater than the start time',
  'plan.notification.description.exitdate': 'The selected date already exists, please select again',
  'plan.notification.description.exitdevice':
    'The device already exists. Please do not add it again',
  'plan.notification.description.appuser': 'Guard must be set',
  'plan.notification.description.copy.success': 'Copy plan successful',
  'plan.notification.description.copyfirst': 'Please copy plan first',
  'plan.notification.description.selfirst': 'Please select a plan',
  'plan.notification.description.selone': 'Please select a record to operate',
  'plan.notification.description.patrolone':
    'The number of patrol times is once and the interval time must be set to zero',
  'plan.Modal.confirm.paste':
    'Are you sure you want to paste the copied plan under the current company?',
  'plan.Modal.confirm.restday': 'Please select a rest day first.',
  'plan.shift.day.wrong':
    'Daily plan can only be created for 31 days at most. Please re-enter the patrol period!',
  'plan.shift.week.wrong':
    'Weekly plan can only be created for 4 weeks at most. Please re-enter the patrol period!',
  'plan.shift.month.wrong':
    'Monthly plan can only be created for 3 months at most. Please re-enter the patrol period!',
  'plan.rest.wrong': 'Cannot set all to weekly rest days, please reset',
  'plan.bin.title': 'Bind existing plan',
  'plan.bin.wrong': 'This operation cannot be performed because the plan is already bound to another temporary plan',
  'plan.bin.tooltip': 'The bound plan will stop generating inspection reports until the temporary plan ends',
};
