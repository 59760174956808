export default {
  'bi.title': 'Πλατφόρμα απεικόνισης Περιπολίας',
  'bi.guard': 'Φύλακας',
  'bi.site': 'Τοποθεσία',
  'bi.source': 'Πηγή',
  'bi.plan': 'Πλάνο',
  'bi.device': 'Συσκευή',
  'bi.record': 'Εγγραφή',

  'bi.time': 'Ώρα',
  'bi.no': 'Αρ.',

  'bi.record.trend': 'Τάση Ροής Δεδομένων',
  'bi.record.average': 'Μέσης τιμής',
  'bi.record.upload': 'Μεταφόρτωση ροής δεδομένων',
  'bi.record.unit': ' ',

  'bi.record.list': 'Λίστα Ροής Δεδομένων',

  'bi.qualified': 'Αρμόδιος',
  'bi.unqualified': 'Παράλλειψη',
  'bi.todo': 'Προς Εκτέλεση',

  'bi.qualified.rate': 'Πιστοποιημένος ρυθμός',
  'bi.unqualified.rate': 'Ρυθμός Παράλειψης',

  'bi.rate.today': 'Σημερινά Στατιστικά',
  'bi.attendacne.rank': 'Βαθμός Παρουσίας',
  'bi.rate.trend': 'Διάγραμμα Στατιστικών',

  monday: 'Δευτέρα',
  tuesday: 'Τρίτη',
  wednesday: 'Τετάρτη',
  thursday: 'Πέμπτη',
  friday: 'Παρασκευή',
  saturday: 'Σάββατο',
  sunday: 'Κυριακή',

  'bi.empty': 'Δεν υπάρχουν δεδομένα',

  'bi.largest.guide':
    'Προσαρμόστε το παράθυρο για να μεγιστοποιήσετε το μέγεθος για να έχετε καλύτερη εμπειρία στην οθόνη BI',
  'bi.largest.ignore': 'Αγνόηση',
};
