export default {
  'user.title': 'Operator',
  'user.btn.add': 'Add Operator',
  'user.btn.dept': 'View Company',
  'user.btn.right': 'Assign Permission',
  'user.btn.init': 'Initialize Password',
  'user.btn.home': 'Return Home',
  'user.table.displayname': 'Login Name',
  'user.table.username': 'Operator Name',
  'user.table.rolename': 'Role',
  'user.table.operate': 'Operate',
  'user.table.edit': 'Edit',
  'user.role.title': 'Assign Permission',
  'user.role.label': 'Role List',
  'user.role.message': 'Please select role',
  'user.input.username.placeholder': 'Please input operator name',
  'user.input.displayname.placeholder': 'Please input login name',
  'user.input.email.placeholder': 'Please input Email',
  'user.btn.init.confirm': 'Sure to restore the original password? (initial password: 999)',

  'user.serial.title': 'System Activation',
  'user.serial.activation': 'Activation code',
  'user.serial.verification': 'Verification Code',
  'user.serial.verification.placeholder': 'Please input verification code',
  'user.app.expand': 'App Quantity Expansion',
  'user.app.count': 'Quantity',
};
