export default {
  'header.guest': 'Guest',
  'header.welcome': 'Hi, friend',
  'header.modifyPassword': 'Modify Password',
  'header.logout': 'Logout',
  'header.read': 'Read Data',
  'header.timing': 'Device Timing',
  'header.report': 'Report',
  'header.history': 'History Data',
  'header.chart': 'Statistic Chart',
  'header.fullScreen': 'Toggle full screen',
  'header.theme': 'Toggle theme',
  'header.theme.light': 'Light',
  'header.theme.dark': 'Dark',
  'header.component.download': 'Driver',

  'header.password.original': 'Original Password',
  'header.password.original.placeholder': 'Please input Original Password',
  'header.password.new': 'New Password',
  'header.password.new.placeholder': 'Please input New Password',
  'header.password.confirm': 'Confirm Password',
  'header.password.confirm.placeholder': 'Please input Confirm Password',
  'header.password.notMatch': 'The two password entries are different, please confirm',
  'header.password.same':
    'The original password is the same as the new password, so there is no need to change the password',
  'header.password.blank': 'Password cannot be blank character(s)',

  'header.password.modify.success': 'Password upgraded, redirect to login after 5s',

  'header.device.connected': 'Device Connected',
  'header.device.disconnected': 'Device Disconnected',
  'header.modifyEmail': 'Modify email',
};
