export default {
  'module.1': 'Patrol Setup',
  'module.101': 'Company Setup',
  'module.10101': 'Add Company',
  'module.10102': 'Edit Company',
  'module.10103': 'Delete Company',
  'module.10104': 'View Company',
  'module.102': 'Event Setup',
  'module.10201': 'Add Event',
  'module.10202': 'Edit Event',
  'module.10203': 'Delete Event',
  'module.10204': 'View menu',
  'module.10205': 'Import Event',
  'module.10206': 'Export Event',
  'module.10207': 'Batch Read',
  'module.103': 'Guard Setup',
  'module.10301': 'Add Guard',
  'module.10302': 'Edit Guard',
  'module.10303': 'Delete Guard',
  'module.10304': 'View menu',
  'module.10305': 'Import Guard',
  'module.10306': 'Export Guard',
  'module.10307': 'Batch Read',
  'module.10308': 'Collect Fingerprint',
  'module.10309': 'Edit fingerprint',
  'module.10310': 'Download Fingerprint',
  'module.104': 'Checkpoint Setup',
  'module.10401': 'Add Checkpoint',
  'module.10402': 'Edit',
  'module.10403': 'Delete Checkpoint',
  'module.10404': 'View menu',
  'module.10405': 'Import Checkpoint',
  'module.10406': 'Download Data',
  'module.10407': 'Batch Read',
  'module.10408': 'Assign Content',
  'module.10409': 'Export Checkpoint',
  'module.105': 'Plan Setup',
  'module.10501': 'Add Plan',
  'module.10502': 'Edit Plan',
  'module.10503': 'Delete Plan',
  'module.10504': 'View menu',
  'module.10505': 'Assign Device',
  'module.10506': 'Export Plan',
  'module.106': 'Inspection device registration',
  'module.10601': 'Device registration',
  'module.10602': 'Delete device',
  'module.10604': 'View menu',
  'module.107': 'Contact Setup',
  'module.10701': 'Add Contacts',
  'module.10702': 'Modify',
  'module.10703': 'Delete',
  'module.10704': 'View menu',
  'module.10706': 'Import Contact',
  'module.10705': 'Export Contact',
  'module.108': 'Receipt Information',
  'module.10801': 'Add Receipt',
  'module.10802': 'Edit receipt information',
  'module.10803': 'Delete receipt information',
  'module.10804': 'View menu',
  'module.10805': 'Import Receipt',
  'module.10806': 'Export Receipt',
  'module.109': 'Download Data',
  'module.10901': 'Download Data',
  'module.10904': 'View menu',
  'module.110': 'Content Setup',
  'module.11001': 'Add Content',
  'module.11002': 'Edit Content',
  'module.11003': 'Delete Content',
  'module.11004': 'View menu',
  'module.11005': 'Select Checkpoint',
  'module.111': 'Walkie-talkie Setup',
  'module.11101': 'Add Group',
  'module.11102': 'Edit Group',
  'module.11103': 'Delete Group',
  'module.11104': 'View menu',
  'module.112': 'Voice Setup',
  'module.11204': 'View menu',
  'module.113': 'Download Data',
  'module.11301': 'Download Data',
  'module.11304': 'View menu',
  'module.114': 'Electronic fence',
  'module.11401': 'New fence',
  'module.11402': 'Edit fence',
  'module.11403': 'Remove fence',
  'module.11404': 'View menu',
  'module.2': 'Basic Operation',
  'module.201': 'Read Data',
  'module.20104': 'Read Data',
  'module.202': 'History Data',
  'module.20201': 'Export historical Data',
  'module.20203': 'Reanalysis',
  'module.20204': 'View menu',
  'module.203': 'Patrol Report',
  'module.20301': 'Export an inspection report',
  'module.20304': 'View menu',
  'module.204': 'Statistic Chart',
  'module.20401': 'Derive statistics',
  'module.20404': 'View menu',
  'module.205': 'Omission Data',
  'module.20501': 'Export omission data',
  'module.20504': 'View menu',
  'module.206': 'Alarm Data',
  'module.20601': 'Export alarm data',
  'module.20603': 'Delete alarm data',
  'module.20604': 'View menu',
  'module.207': 'Log Information',
  'module.20701': 'Export Log Data',
  'module.20704': 'View menu',
  'module.208': 'Clear Device Data',
  'module.20804': 'Clear Device Data',
  'module.209': 'Device Timing',
  'module.20904': 'Device Timing',
  'module.210': 'Remove Fingerprints',
  'module.21004': 'Remove Fingerprints',
  'module.211': 'Monitoring',
  'module.21101': 'Edit point',
  'module.21102': 'Track Playback',
  'module.21104': 'View menu',
  'module.21105': 'View live data',
  'module.212': 'Original Data',
  'module.21201': 'Export raw data',
  'module.21202': 'Unknown tag conversion',
  'module.21204': 'View menu',
  'module.213': 'Patrol Calendar',
  'module.21304': 'View menu',
  'module.214': 'Attendance data',
  'module.21401': 'Export attendance data',
  'module.21404': 'View menu',
  'module.215': 'Report Detail',
  'module.21501': 'Export inspection Details',
  'module.21504': 'View menu',
  'module.3': 'Data Maintenance',
  'module.302': 'Device List',
  'module.30201': 'Activate Device',
  'module.30202': 'Add Device',
  'module.30203': 'Delete device',
  'module.30204': 'View menu',
  'module.30205': 'Replace Device',
  'module.30206': 'Parameter Setup',
  'module.30207': 'Edit Remark',
  'module.303': 'System Settings',
  'module.30304': 'System Settings',
  'module.304': 'Device Settings',
  'module.30404': 'View device parameter Settings',
  'module.30408': 'Clock Setup',
  'module.305': 'Communication Setup',
  'module.30504': 'View communication parameter Settings',
  'module.306': 'Permission Setup',
  'module.30601': 'Add Role',
  'module.30602': 'Edit Role',
  'module.30603': 'Delete Role',
  'module.30604': 'View menu',
  'module.307': 'Operator Setup',
  'module.30701': 'Add Operator',
  'module.30702': 'Edit Operator',
  'module.30703': 'Delete Operator',
  'module.30704': 'View menu',
  'module.30705': 'View Company',
  'module.30706': 'Assign Permission',
  'module.30708': 'Modify Password',
  'module.308': 'Device Initialization',
  'module.30804': 'Device Initialization',
  'module.5': 'Dashboard',
  'module.216': 'BI',
  'module.21604': 'View menu',
};
