export default {
  confirm: '确定',
  cancel: '取消',
  back: '返回',
  'general.homeSwitch.title.old': '不习惯当前首页，切换回旧版？',
  'general.homeSwitch.title.new': '想体验一下全新设计的首页？',
  'general.WePatrol.qr.download.text': '扫描下载巡检宝App',
  'general.WePatrol.qr.use.text': '使用 [ {code} ] 作为注册编码注册账号并登录',
  'general.WePatrol.qr.login.text': '扫描二维码登录App',
  'general.guest.alert':
    '当前为访客模式，所展示数据为实时生成的非真实模拟演示数据，仅供浏览使用...',
  'general.unhandled.alert': '当前系统内有 {count} 条数据正在处理中，请耐心等待...',
};
