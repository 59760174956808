export default {
  'book.title': '通讯录',
  'book.btn.add': '增加通讯录',
  'book.btn.import': '导入通讯录',
  'book.btn.export': '导出通讯录',
  'book.table.name': '联系人姓名',
  'book.table.tel': '联系人电话',
  'book.input.name': '请输入联系人姓名',
  'book.input.tel': '请输入联系人电话',
  'book.table.moveup': '上移通讯录',
  'book.table.movedown': '下移通讯录',
};
