export default {
  'role.title': 'Настройка ролей',
  'role.btn.add': 'Добавить роль',
  'role.btn.save': 'Сохранить роль',
  'role.btn.home': 'Вернуться домой',
  'role.table.name': 'Название роли',
  'role.table.operate': 'действовать',
  'role.table.edit': 'редактировать',
  'role.input.name.placeholder': 'Введите название роли',
  'role.notification.module.description': 'Пожалуйста, выберите информацию о модуле',
  'role.notification.role.description': 'Пожалуйста, выберите информацию о роли',
};
