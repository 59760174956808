export default {
  'query.condition.dept': "Nom de l'entreprise",
  'query.condition.guard': 'Nom du garde',
  'query.condition.site': 'Nom du point de contrôle',
  'query.condition.event': "Nom de l'événement",
  'query.condition.plan': 'Nom du régime',
  'query.condition.planguard': 'Garde du plan',
  'query.condition.device': 'Code appareil',
  'query.condition.begin': 'Heure de début',
  'query.condition.end': 'Heure de fin',
  'query.condition.time': 'Temps de patrouille',
  'query.condition.alarmtype': "Type d'alarme",
  'query.input.placeholder.site': 'Veuillez sélectionner un point de contrôle',
  'query.input.placeholder.guard': 'Veuillez sélectionner la garde',
  'query.input.placeholder.device': "Veuillez sélectionner l'appareil",
  'query.input.placeholder.plan': 'Veuillez sélectionner un forfait',
  'query.input.placeholder.alarm': "Veuillez sélectionner le type d'alarme",
  'query.history.title': "Données d'historique",
  'query.history.btn.reanalysis': 'Réanalyse',
  'query.app.remark': 'Contenu et notes',
  'query.app.multimedia': 'Multimédia',
  'query.app.view': 'Voir',
  'query.app.notes': 'Remarques',
  'query.app.view.pict': 'Voir les photos',
  'query.app.view.video': 'Voir la vidéo',
  'query.app.view.audio': "Afficher l'audio",
  'query.app.options': 'Options de contenu',
  'query.app.state': 'État',
  'query.app.notentered': 'Non entré',
  'query.app.notselected': 'Non séléctionné',
  'query.app.selected': 'Selected',
  'query.app.site.errspan':
    "La plage d'erreur de latitude et de longitude actuelle dépasse la plage spécifiée, le point de contrôle peut ne pas être un point de contrôle valide",
  'query.app.site.time': 'Le temps de patrouille actuel peut avoir été modifié',
  'query.app.site.both':
    "La plage d'erreur de latitude et de longitude actuelle dépasse la plage spécifiée, le point de contrôle peut ne pas être un point de contrôle valide, Le temps de patrouille actuel peut avoir été modifié",
  'query.alarm.title': "Données d'alarme",
  'query.alarm.btn.del': 'Suprimmer les données',
  'query.alarm.type.all': 'Toutes les alarmes',
  'query.alarm.type.manual': 'Alarme manuelle',
  'query.alarm.type.lowvoltage': 'Alarme basse tension',
  'query.alarm.type.incline': "Alarme d'inclinaison",
  'query.alarm.type.static': 'Alarme statique',
  'query.alarm.type.impact': "Alarme d'impact",
  'query.alarm.type.open': 'Alarme coque ouverte',
  'query.alarm.info': "Informations sur l'alarme",
  'query.alarm.time': "Heure de l'alarme",
  'query.alarm.position': 'Alarm Position',
  'query.omit.title': "Données d'omission",
  'query.omit.rangetime': 'De à',

  'query.chart.title': 'Statistiques',
  'query.chart.table.plantimes': 'Planifier les heures',
  'query.chart.table.arrivedtimes': "Heures d'arrivée",
  'query.chart.table.omittimes': "Temps d'omission",
  'query.chart.table.arrivedrate': "Taux d'arrivée",
  'query.chart.table.omitrate': "Taux d'omission",
  'query.chart.table.summary': 'Résumé des données',
  'query.chart.tab.data': 'Donnée statistique',
  'query.chart.tab.chart': 'Affichage graphique',
  'query.chart.tab.chart.column': 'Graphiques à colonnes',
  'query.chart.tab.chart.bar': 'Diagramme à barres',
  'query.chart.tab.chart.line': 'Graphiques linéaires',
  'query.chart.tab.omit': "Données d'omission",
  'query.chart.condition.type': 'Type de statistiques',
  'query.chart.condition.dept': "Statistiques en tant qu'entreprise",
  'query.chart.condition.guard': 'Statistiques en tant que garde',
  'query.chart.condition.site': 'Statistiques comme point de contrôle',
  'query.chart.condition.plan': 'Statistiques comme plan',

  'query.raw.title': "Données d'origine",
  'query.raw.table.card': 'ID de la carte',
  'query.raw.table.type': 'Type de carte',
  'query.raw.table.name': 'Nom de la carte',
  'query.raw.table.unknow': 'Carte inconnue',
  'query.raw.table.event': "Carte d'événement",
  'query.raw.table.guard': 'Carte de garde',
  'query.raw.table.site': 'Carte de point de contrôle',
  'query.raw.table.time': 'Temps de vérification',
  'query.raw.btn.unknow': 'Conversion de carte',
  'query.raw.btn.site': 'Convertir en point de contrôle',
  'query.raw.btn.guard': 'Convertir en garde',
  'query.raw.btn.event': 'Convertir en événement',

  'query.log.title': 'Informations sur le journal',
  'query.log.table.operate': 'Interface de fonctionnement',
  'query.log.table.content': "Contenu de l'opération",
  'query.log.table.time': 'Temps de fonctionnement',
  'query.log.table.user': 'Opérateur',
  'query.log.form.dept': "Configuration de l'entreprise",
  'query.log.form.guard': 'Dispositifs de protection',
  'query.log.form.site': 'Paramètres du point de contrôle',
  'query.log.form.plan': 'Paramètres du programme',
  'query.log.form.read': 'Lire les données',

  'query.calendar.january': 'Janvier',
  'query.calendar.february': 'Février',
  'query.calendar.march': 'Mars',
  'query.calendar.april': 'Avril',
  'query.calendar.may': 'Mai',
  'query.calendar.june': 'Juin',
  'query.calendar.july': 'Juillet',
  'query.calendar.august': 'Août',
  'query.calendar.september': 'Septembre',
  'query.calendar.october': 'Octobre',
  'query.calendar.november': 'Novembre',
  'query.calendar.december': 'Décembre',
  'query.calendar.year': '-',
  'query.calendar.year1': ' ',
  'query.calendar.month': ' ',

  'query.report.condition.type': 'Type de données',
  'query.report.condition.all': 'Toutes les données',
  'query.report.condition.normal': 'Données normales',
  'query.report.condition.arrived': 'Données d’arrivée',
  'query.report.condition.todo': 'Données de tâches',
  'query.report.table.alltimes': 'Chaque fois',
  'query.report.table.patroltimes': 'Temps de patrouille',
  'query.report.table.topatrol': 'Aux heures de patrouille ',
  'query.report.table.sequence.right': 'Le bon ordre',
  'query.report.table.sequence.err': 'Erreur de séquence',
  'query.report.btn.export.summary': 'Exporter le rapport récapitulatif',
  'query.report.btn.export.ordinary': 'Exporter le rapport ordinaire',
  'query.report.btn.export.summaryname': 'Rapporter les données récapitulatives',
  'query.report.btn.export.ordinaryname': 'Rapporter des données ordinaires',
  'query.report.table.step': 'Étape',
  'query.report.btn.del': 'Supprimer les données du rapport',
  'query.history.analysis': "Confirmer aux données d'analyse?",
  'query.content.fold': 'Crash',
  'query.content.expand': 'Étendre tous',
};
