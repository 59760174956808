export default {
  'exception.fail': 'Ошибка запроса',
  'exception.unknow': 'Произошла неизвестная проблема',
  'exception.network': 'Сетевое исключение',
  'exception.network.desc': 'В сети произошло исключение, невозможно подключиться к серверу',

  'exception.403': 'Нет разрешения на доступ к текущему ресурсу',
  'exception.404': 'Не удалось найти запрошенный в данный момент ресурс',
  'exception.500': 'Кажется, на сервере возникли некоторые проблемы',

  'exception.400': 'В отправленном запросе есть ошибка',
  'exception.401': 'Учетные данные пользователя недействительны',
  'исключение.502': '502',
  'исключение.503': '503',
  'exception.504': 'Время ожидания шлюза истекло',

  'exception.crash': 'Я зашел, в программе, похоже, какие-то сбои...',
  'exception.disconnect': 'Unable to load resources from server...',
};
