export default {
  'bi.title': 'Визуализация данных осмотра на большом экране',
  'bi.guard': 'персонал',
  'bi.site': 'Место',
  'bi.source': 'источник',
  'bi.plan': 'план',
  'bi.device': 'оборудование',
  'bi.record': 'Записывать',

  'bi.time': 'время',
  'bi.no': 'серийный номер',

  'bi.record.trend': 'Тенденции трафика данных',
  'bi.record.average': 'средний трафик',
  'bi.record.upload': 'загрузить трафик',
  'bi.record.unit': 'полоска',

  'bi.record.list': 'Детали трафика данных',
  'bi.record.': 'Детали трафика данных',

  'bi.qualified': 'квалифицированный',
  'bi.unqualified': 'пропущенный патруль',
  'bi.todo': 'ожидающий',

  'bi.qualified.rate': 'Проходной балл',
  'bi.unqualified.rate': 'Отсутствует скорость патрулирования',

  'bi.rate.today': 'Сегодняшняя статистика проходимости',
  'bi.attendacne.rank': 'Таблица лидеров посещаемости',
  'bi.rate.trend': 'Тенденция проходного балла',

  monday: 'Понедельник',
  tuesday: 'Вторник',
  wednesday: 'Среда',
  thursday: 'Четверг',
  friday: 'Пятница',
  saturday: 'Суббота',
  sunday: 'Воскресенье',

  'bi.empty': 'Нет данных',

  'bi.largest.guide':
    'Пожалуйста, вручную отрегулируйте окно браузера, чтобы увеличить его для достижения лучшего эффекта отображения на большом экране.',
  'bi.largest.ignore': 'войти напрямую',
};
