// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from 'D:/MaYun.UI/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/PageLoading/index';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BlankLayout' */'D:/MaYun.UI/src/layouts/BlankLayout'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/login",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login' */'D:/MaYun.UI/src/pages/Login'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__SecurityLayout' */'D:/MaYun.UI/src/layouts/SecurityLayout'), loading: LoadingComponent}),
        "routes": [
          {
            "path": "/",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__BasicLayout' */'D:/MaYun.UI/src/layouts/BasicLayout'), loading: LoadingComponent}),
            "routes": [
              {
                "path": "/",
                "redirect": "/dashboard",
                "exact": true
              },
              {
                "path": "/dashboard",
                "name": "dashboard",
                "icon": "dashboard",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Dashboard' */'D:/MaYun.UI/src/pages/Dashboard'), loading: LoadingComponent}),
                "authority": "5",
                "exact": true
              },
              {
                "path": "/setup",
                "name": "setup",
                "module": "1",
                "icon": "control",
                "authority": "1",
                "routes": [
                  {
                    "path": "/setup/dept",
                    "name": "dept",
                    "module": "101",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Dept' */'D:/MaYun.UI/src/pages/Setup/Dept'), loading: LoadingComponent}),
                    "authority": "10104",
                    "exact": true
                  },
                  {
                    "path": "/setup/event",
                    "name": "event",
                    "module": "102",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Event' */'D:/MaYun.UI/src/pages/Setup/Event'), loading: LoadingComponent}),
                    "authority": "10204",
                    "exact": true
                  },
                  {
                    "path": "/setup/guard",
                    "name": "guard",
                    "module": "103",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Guard' */'D:/MaYun.UI/src/pages/Setup/Guard'), loading: LoadingComponent}),
                    "authority": "10304",
                    "exact": true
                  },
                  {
                    "path": "/setup/site",
                    "name": "site",
                    "module": "104",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Site' */'D:/MaYun.UI/src/pages/Setup/Site'), loading: LoadingComponent}),
                    "authority": "10404",
                    "exact": true
                  },
                  {
                    "path": "/setup/content",
                    "name": "content",
                    "module": "110",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Content' */'D:/MaYun.UI/src/pages/Setup/Content'), loading: LoadingComponent}),
                    "authority": "11004",
                    "exact": true
                  },
                  {
                    "path": "/setup/plan",
                    "name": "plan",
                    "module": "105",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Plan' */'D:/MaYun.UI/src/pages/Setup/Plan'), loading: LoadingComponent}),
                    "authority": "10504",
                    "exact": true
                  },
                  {
                    "path": "/setup/voice",
                    "name": "voice",
                    "module": "112",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Voice' */'D:/MaYun.UI/src/pages/Setup/Voice'), loading: LoadingComponent}),
                    "authority": "11204",
                    "exact": true
                  },
                  {
                    "path": "/setup/book",
                    "name": "book",
                    "module": "107",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Book' */'D:/MaYun.UI/src/pages/Setup/Book'), loading: LoadingComponent}),
                    "authority": "10704",
                    "exact": true
                  },
                  {
                    "path": "/setup/receipt",
                    "name": "receipt",
                    "module": "108",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Receipt' */'D:/MaYun.UI/src/pages/Setup/Receipt'), loading: LoadingComponent}),
                    "authority": "10804",
                    "exact": true
                  },
                  {
                    "path": "/setup/downparam",
                    "name": "downparam",
                    "module": "109",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__DownParam' */'D:/MaYun.UI/src/pages/Setup/DownParam'), loading: LoadingComponent}),
                    "authority": "10904",
                    "exact": true
                  },
                  {
                    "path": "/setup/talk",
                    "name": "talk",
                    "module": "111",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Talk' */'D:/MaYun.UI/src/pages/Setup/Talk'), loading: LoadingComponent}),
                    "authority": "11104",
                    "exact": true
                  },
                  {
                    "path": "/setup/duty",
                    "name": "duty",
                    "module": "113",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Duty' */'D:/MaYun.UI/src/pages/Setup/Duty'), loading: LoadingComponent}),
                    "authority": "11304",
                    "exact": true
                  },
                  {
                    "path": "/setup/enclosure",
                    "name": "enclosure",
                    "module": "114",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setup__Enclosure' */'D:/MaYun.UI/src/pages/Setup/Enclosure'), loading: LoadingComponent}),
                    "authority": "11404",
                    "exact": true
                  },
                  {
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__404' */'D:/MaYun.UI/src/pages/Exception/404'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/basic",
                "name": "basic",
                "module": "2",
                "icon": "database",
                "authority": "2",
                "routes": [
                  {
                    "path": "/basic/report",
                    "name": "report",
                    "module": "203",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basic__Report' */'D:/MaYun.UI/src/pages/Basic/Report'), loading: LoadingComponent}),
                    "authority": "20304",
                    "exact": true
                  },
                  {
                    "path": "/basic/detail",
                    "name": "detail",
                    "module": "215",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basic__Detail' */'D:/MaYun.UI/src/pages/Basic/Detail'), loading: LoadingComponent}),
                    "authority": "21504",
                    "exact": true
                  },
                  {
                    "path": "/basic/history",
                    "name": "history",
                    "module": "202",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basic__History' */'D:/MaYun.UI/src/pages/Basic/History'), loading: LoadingComponent}),
                    "authority": "20204",
                    "exact": true
                  },
                  {
                    "path": "/basic/chart",
                    "name": "chart",
                    "module": "204",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basic__Chart' */'D:/MaYun.UI/src/pages/Basic/Chart'), loading: LoadingComponent}),
                    "authority": "20404",
                    "exact": true
                  },
                  {
                    "path": "/basic/omit",
                    "name": "omit",
                    "module": "205",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basic__Omit' */'D:/MaYun.UI/src/pages/Basic/Omit'), loading: LoadingComponent}),
                    "authority": "20504",
                    "exact": true
                  },
                  {
                    "path": "/basic/alarm",
                    "name": "alarm",
                    "module": "206",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basic__Alarm' */'D:/MaYun.UI/src/pages/Basic/Alarm'), loading: LoadingComponent}),
                    "authority": "20604",
                    "exact": true
                  },
                  {
                    "path": "/basic/realmap",
                    "name": "realmap",
                    "module": "211",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__RealTimeMap' */'D:/MaYun.UI/src/pages/RealTimeMap'), loading: LoadingComponent}),
                    "authority": "21104",
                    "exact": true
                  },
                  {
                    "path": "/basic/calendar",
                    "name": "calendar",
                    "module": "213",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basic__Calendar' */'D:/MaYun.UI/src/pages/Basic/Calendar'), loading: LoadingComponent}),
                    "authority": "21304",
                    "exact": true
                  },
                  {
                    "path": "/basic/raw",
                    "name": "raw",
                    "module": "212",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basic__Raw' */'D:/MaYun.UI/src/pages/Basic/Raw'), loading: LoadingComponent}),
                    "authority": "21204",
                    "exact": true
                  },
                  {
                    "path": "/basic/attendance",
                    "name": "attendance",
                    "module": "214",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basic__Attendance' */'D:/MaYun.UI/src/pages/Basic/Attendance'), loading: LoadingComponent}),
                    "authority": "21404",
                    "exact": true
                  },
                  {
                    "path": "/basic/log",
                    "name": "log",
                    "module": "207",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Basic__Log' */'D:/MaYun.UI/src/pages/Basic/Log'), loading: LoadingComponent}),
                    "authority": "20704",
                    "exact": true
                  },
                  {
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__404' */'D:/MaYun.UI/src/pages/Exception/404'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/data",
                "name": "data",
                "module": "3",
                "icon": "bulb",
                "authority": "3",
                "routes": [
                  {
                    "path": "/data/device",
                    "name": "device",
                    "module": "302",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__Device' */'D:/MaYun.UI/src/pages/Data/Device'), loading: LoadingComponent}),
                    "authority": "30204",
                    "exact": true
                  },
                  {
                    "path": "/data/system",
                    "name": "system",
                    "module": "303",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__System' */'D:/MaYun.UI/src/pages/Data/System'), loading: LoadingComponent}),
                    "authority": "30304",
                    "exact": true
                  },
                  {
                    "path": "/data/role",
                    "name": "role",
                    "module": "306",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__Role' */'D:/MaYun.UI/src/pages/Data/Role'), loading: LoadingComponent}),
                    "authority": "30604",
                    "exact": true
                  },
                  {
                    "path": "/data/user",
                    "name": "user",
                    "module": "307",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__User' */'D:/MaYun.UI/src/pages/Data/User'), loading: LoadingComponent}),
                    "authority": "30704",
                    "exact": true
                  },
                  {
                    "path": "/data/system/backup",
                    "name": "backup",
                    "module": "",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Data__System__DbBackup' */'D:/MaYun.UI/src/pages/Data/System/DbBackup'), loading: LoadingComponent}),
                    "authority": "30305",
                    "hideInMenu": true,
                    "exact": true
                  },
                  {
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__404' */'D:/MaYun.UI/src/pages/Exception/404'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "path": "/company",
                "name": "company",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Company' */'D:/MaYun.UI/src/pages/Company'), loading: LoadingComponent}),
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/bi",
                "name": "bi",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__BI' */'D:/MaYun.UI/src/pages/BI'), loading: LoadingComponent}),
                "authority": "21604",
                "hideInMenu": true,
                "exact": true
              },
              {
                "path": "/exception",
                "hideInMenu": true,
                "routes": [
                  {
                    "path": "/exception/403",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__403' */'D:/MaYun.UI/src/pages/Exception/403'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/exception/404",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__404' */'D:/MaYun.UI/src/pages/Exception/404'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "path": "/exception/500",
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__500' */'D:/MaYun.UI/src/pages/Exception/500'), loading: LoadingComponent}),
                    "exact": true
                  },
                  {
                    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__404' */'D:/MaYun.UI/src/pages/Exception/404'), loading: LoadingComponent}),
                    "exact": true
                  }
                ]
              },
              {
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__404' */'D:/MaYun.UI/src/pages/Exception/404'), loading: LoadingComponent}),
                "exact": true
              }
            ]
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__404' */'D:/MaYun.UI/src/pages/Exception/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Exception__404' */'D:/MaYun.UI/src/pages/Exception/404'), loading: LoadingComponent}),
    "exact": true
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
