import request from '@/utils/request';
import { stringify } from 'qs';

export async function getDataReport(params) {
  return request(`/api/report?${stringify(params)}`);
}

export async function getDetailDataReport(params) {
  return request(`/api/report/info?${stringify(params)}`);
}

export async function getContentDetail(params) {
  return request(`/api/report/content?${stringify(params)}`);
}

export async function getContenItemDetail(params) {
  return request(`/api/report/tourContent?${stringify(params)}`);
}
