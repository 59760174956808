export default {
  'common.table.batchdel': 'Μαζική Διαγραφή',
  'common.table.del': 'Διαγραφή',
  'common.table.moveup': 'Μετακίνηση πάνω',
  'common.table.movedown': 'Μετακίνηση κάτω',
  'common.table.cancel': 'Ακύρωση',
  'common.table.selected': 'Επιλεγμένα',
  'common.table.item': 'Αντικείμενα',
  'common.btn.success': 'Επιτυχής Λειτουργία',
  'common.btn.download.success': 'Επιτυχής λήψη δεδομένων',
  'common.btn.download.failed': 'Αποτυχία λήψης δεδομένων',
  'common.btn.confirm': 'Επιβεβαίωση',
  'common.btn.cancel': 'Ακύρωση',
  'common.btn.close': 'Κλείσιμο',
  'common.btn.template': 'Λήψη προτύπου',
  'common.btn.import.label': 'Όνομα Αρχείου',
  'common.btn.export.excel': 'Εξαγωγή σε Excel',
  'common.btn.export.pdf': 'Εξαγωγή σε Pdf',
  'common.btn.import.title': 'Click για λήψη',
  'common.btn.home': 'Αρχική σελίδα',
  'common.message.prompt': 'προτροπή',
  'common.placeholder.message': 'Παρακαλώ εισάγετε',
  'common.device.nodata': 'όχι δεδομένα σε αυτή τη συσκευή',
  'common.notification.description.nodata':
    'Δεν βρέθηκαν εγγραφές που να ταιριάζουν, αλλάξτε τις επιλογές σας.',
  'common.notification.description.batchsuccess':
    'Επιτυχής μαζική ανάγνωση. Ο συνολικός αριθμός εγγραφών είναι: ',
  'common.notification.description.records':
    'Ο αριθμός των εγγραφών που έχουν πράγματι εισαχθεί είναι: ',
  'common.notification.description.required': 'Αυτό το αντικείμενο απαιτείται',
  'common.notification.query.range':
    'Το χρονικό διάστημα αναζήτησης δεν μπορεί να υπερβαίνει τις 60 ημέρες. Επιλέξτε ξανά τα κριτήρια αναζήτησης',
  'common.websocket.version':
    'Η τρέχουσα έκδοση του οδηγού είναι σχετικά χαμηλή. Παρακαλώ κατεβάστε ξανά την τελευταία έκδοση!',
};
