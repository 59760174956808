export default {
  'book.title': '通訊錄',
  'book.btn.add': '增加通訊錄',
  'book.btn.import': '導入通訊錄',
  'book.btn.export': '導出通訊錄',
  'book.table.name': '聯絡人姓名',
  'book.table.tel': '聯絡人電話',
  'book.input.name': '請輸入聯絡人姓名',
  'book.input.tel': '請輸入聯絡人電話',
  'book.table.moveup': '上移通訊錄',
  'book.table.movedown': '下移通訊錄',
};
