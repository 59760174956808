export default {
  'role.title': 'Ρόλος',
  'role.btn.add': 'Προσθήκη Ρόλου',
  'role.btn.save': 'Αποθήκευση Ρόλου',
  'role.btn.home': 'Επιστροφή σε Αρχική σελίδα',
  'role.table.name': 'Ονομασία Ρόλου',
  'role.table.operate': 'Χειρισμός',
  'role.table.edit': 'Επεξεργασία',
  'role.input.name.placeholder': 'Παρακαλώ εισάγετε ονομασία Ρόλου',
  'role.notification.module.description': 'Παρακαλώ επιλέξτε μονάδα πρώτα',
  'role.notification.role.description': 'Παρακαλώ επιλέξτε Ρόλο πρώτα',
};
