export default {
  'system.title': '系統參數設置',
  'system.event': '事件功能',
  'system.event.des': '是否支持事件功能',
  'system.plan': '按次計劃',
  'system.plan.des': '能夠周期性執行的計劃，例如每天、每周、每月執行',
  'system.patroltime': '巡檢用時',
  'system.patroltime.des': '是否在巡檢報告中顯示每圈計劃巡檢總時間',
  'system.remaintime': '停留時間',
  'system.remaintime.des': '是否在巡檢報告中顯示每圈計劃巡檢總時間',
  'system.Sequence': '順序巡檢',
  'system.Sequence.des': '是否指定計劃內的每個巡檢點必須按照計劃中設置的順序進行巡檢',
  'system.timeformate': '日期格式',
  'system.timeformate.des': '系統顯示的日期格式',
  'system.dst': '夏令時',
  'system.dst.des': '當前企業是否啓用夏令時模式',
  'system.dst.preview': '夏令時週期：{beginTime}～{endTime}，跨度：{spanHour}小時',
  'system.dst.beginMonth': '開始月份',
  'system.dst.endMonth': '結束月份',
  'system.dst.spanHour': '跨度（小時）',
  'system.dst.weekVal': '星期',
  'system.dst.weekIndex': '第{indexVal}個',
  'system.dst.dataNullErrorMsg': '夏令時未配置，請修改',
  'system.dst.beginMonthGeEndError': '開始月份必須小於結束月份',
  'system.dst.weekIndex.tooltip':
    '如果月份內沒有指定數量的星期數，那麼程序將按照最後一週的指定星期來計算。',
  'system.database.period': '數據庫備份周期',
  'system.database.period.des': '多久對數據庫進行備份一次，單位：天',
  'system.database.time': '數據庫備份時間',
  'system.database.time.des': '請盡量選擇在數據上傳低峰時間',
  'system.database.path': '數據庫備份路徑',
  'system.database.path.des': '請輸入數據庫備份路徑，例如：D:\\backup，路徑為空則代表不備份數據庫',
  'system.save': '保存設置',
  'system.enable': '啓用',
  'system.disable': '關閉',
  'system.notification.prompt': '提示',
  'system.notification.success': '保存成功！',
  'system.deptBaseDataCalMode.title': '區域基礎數據計算方式',
  'system.deptBaseDataCalMode.desc':
    '巡檢設置下各基礎數據管理界面，區域樹形節點下基礎類型數量的計算方式',
  'system.deptBaseDataCalMode.options.currentDept': '僅計算當前區域',
  'system.deptBaseDataCalMode.options.childrenDept': '計算當前區域及下級所有',
  'system.lowAutoSendInterval.title': '低電量報警間隔時間（單位：分鐘）',
  'system.lowAutoSendInterval.desc': '設置設備低電量報警時，推送數據並提醒用戶的時間間隔',
  'system.realTimeMonitorModule.title': '實時監控模式',
  'system.realTimeMonitorModule.desc': '設置實時監控使用地圖或示意圖，也關聯影響着電子圍欄是否生效',
  'system.realTimeMonitorModule.options.map': '在線地圖',
  'system.realTimeMonitorModule.options.imgFile': '示意圖',
  'system.compantTimezoneUpt.syncMsg': '企業默認時區發生變更，是否同步未變動過的區域時區',
  'system.timezone.des': '當前企業的默認時區，主要應用於區域的時區配置，和設備校時等涉及時區的功能',
  'system.deptTimezone.title': '區域時區設置',
  'system.deptTimezone.des': '是否支持區域自定義調整時區，開啓後也將允許設備自行管理所在時區，關閉後還原時區爲企業時區。',
};
