export default {
  // 表格
  'company.table.index': '序號',
  'company.table.buy.count': '購買數量',
  'company.table.remain.count': '剩余数量',
  'company.table.term': '剩餘數量',
  'company.table.buy.time': '購買時間',
  'company.table.status': '狀態',
  'company.table.device.type': '設備類型',
  'company.table.operate': '操作',
  'company.table.name': '企業名稱',
  'company.table.code': '企業編碼',
  'company.table.software.version': '軟體版本',
  'company.table.begin.date': '開始時間',
  'company.table.end.date': '截止時間',
  'company.table.sales.man': '銷售人員',
  'company.table.device.model': '設備型號',
  'company.table.device.count': '設備數量',
  'company.table.expire.query': '到期査詢',
  'company.table.date.filer': '日期過濾',
  // 企业
  'company.info.list': '企業信息清單',
  'company.save': '保存',
  'company.yes': '是',
  'company.no': '否',
  'company.edit': '編輯',
  'company.version.all': '全部版本',
  'company.version.formal': '正式版',
  'company.version.try': '試用版',
  'company.version.demo': '演示版',
  'company.version.network': '網絡注册版',
  'company.version.free': '免費版',
  'company.all': '全部企業',
  'company.soon.expire': '即將到期企業',
  'company.already.expire': '已經到期企業',
  'company.adding': '正在新增...',
  'company.add.fail': '新增企業失敗',
  'company.changing': '正在修改...',
  'company.change.fail': '修改企業失敗',
  'company.btn.add': '新增企業',
  'company.btn.card': '充值卡信息',
  'comany.btn.delete': '删除企業',
  'company.delete.tips': '確定要删除選定的企業嗎？',
  'company.reset.password': '重置密碼',
  'company.reset.password.select': '請先選擇要重置密碼的企業',
  'company.reset.password.success': '重置密碼成功',
  'company.renewal.customer': '續費客戶信息',
  'company.register.device.all': '全部注册設備信息',
  'company.register.device.soon': '即將到期設備信息',
  'company.register.device.expire': '已經到期設備信息',
  'company.register.company.count': '當前注册企業數量',
  'company.register.soon.count': '即將到期企業數量',
  'company.register.expire.count': '已經到期企業數量',
  'company.register.device.all.count': '當前注册設備數量',
  'company.register.device.soon.count': '即將到期設備數量',
  'company.register.device.expire.count': '已經到期設備數量',
  'company.search': '搜索',
  'company.reset': '重置',
  'company.filter': '篩選',
  'company.recharge.device.count': '充值設備數量',
  'company.renewal.time': '續費時間',
  'company.renewal.duration': '續費時長',
  // 充值卡
  'company.card.day': '日',
  'company.card.month': '個月',
  'company.card.add.month': '月',
  'company.card.year': '年',
  'company.card.stop': '停用',
  'company.card.start': '啟用',
  'company.card.delete.fail': '該充值卡已被使用，不能删除！',
  'company.card.delete.tips': '確定要删除選定的充值卡信息嗎？',
  'company.card.add.fail': '新增充值卡失敗',
  'company.card.info.setting': '充值卡信息设置',
  'company.card.pls.buy.count': '請輸入購買數量',
  'company.card.pls.term': '請輸入使用期限',
  'company.card.term.unit': '期限組織',
  'company.card.select.sale.device.model': '請選擇銷售設備型號',
  'company.card.device.model.not': '企業設備型號為空，無法添加充值卡信息！',
  // 统计
  'company.device.type.statistics': '設備類型統計',
  'company.sales.man.statistics': '銷售人員統計',
  'company.sales.device.statistics': '銷售設備統計',
  'company.sales.device.count.statistics': '月銷售設備數量統計',
  'company.sales.device.count': '銷售設備數量',
  'company.new.customers.statistics': '新增客戶統計',
  'company.customers.register.count': '客戶注册數量',
  'company.new.customers.info.statistics': '新增客戶信息統計',
  'company.system.access.statistics': '系統訪問記錄',
  'company.sales.man.info.statistics': '銷售人員信息統計',
  'company.date': '日期',
  'company.login.count': '登錄次數',
  'company.one.month.login.count': '過去一個月碼雲系統登入頻次統計',
  // 新增企业
  'company.add.set': '企業信息设置',
  'company.add.basics': '基礎信息',
  'company.add.module.share': '巡檢寶設備不能與其他設備共用！',
  'company.add.country.code.fail': '國家代碼輸入不正確，請重新輸入！',
  'company.add.pls.name': '請輸入企業名稱',
  'company.add.pls.code': '請輸入企業編碼',
  'company.add.pls.model': '請輸入設備型號',
  'company.add.wrongful.code': '不合法的企業編碼',
  'company.add.code.length': '企業編碼長度不能小於四位',
  'company.add.contact': '聯系人',
  'company.add.pls.contact': '請輸入聯系人',
  'company.add.contact.tel': '聯系電話',
  'company.add.pls.contact.tel': '請輸入聯系電話',
  'company.add.email': '電子郵箱',
  'company.add.pls.email': '請輸入電子郵箱',
  'company.add.bengin.time': '開始使用時間',
  'company.add.end.time': '截止使用時間',
  'company.add.province': '所在省份',
  'company.add.city': '所在城市',
  'company.add.pls.city': '請輸入所在城市',
  'company.add.register.time': '註冊時間',
  'company.add.sale.device.model': '銷售設備型號',
  'company.add.phone.app.login': '手機APP登錄',
  'company.add.select.sale.device.model': '请选择銷售設備型號',
  'company.add.configure': '配寘信息',
  'company.add.read.type': '讀取數據方式',
  'company.add.omit.push.func': '漏檢推送功能',
  'company.add.create.user': '開通人員',
  'company.add.time.zone': '所處時區',
  'company.add.time.zone.des': '請與你當地時區保持一致',
  'company.add.country.code': '國家代碼',
  'company.add.country.app': '巡檢寶最大用戶數',
  'company.add.pls.country.code': '請輸入國家代碼',
  'company.add.show.device.model': '顯示設備型號',
  'company.add.no.show.device.model': '不顯示設備型號',
  'company.add.data.package': '數據包方式',
  'company.add.base64.mode': 'Base64方式',
  'company.add.no.app.push.func': '無APP端推送功能',
  'company.add.app.push.func': '有APP端推送功能',
  'company.add.charge.or.not': '是否收費',
  'company.add.no.charge': '不收費',
  'company.add.charge': '收費',
  'company.add.companycode.info': '不能使用 guest 或 administrator 作爲企業編碼，請重新輸入！',
  // 基础数据信息
  'company.basics.info': '基礎數據信息',
  'company.basics.dept.name': '區域名稱',
  'company.basics.site.list': '地點清單',
  'company.basics.site.name': '地點名稱',
  'company.basics.site.code': '地點卡號',
  'company.basics.site.delete.tips': '確定要删除選定的地點信息嗎？',
  'company.basics.user.list': '人員清單',
  'company.basics.user.name': '人員名稱',
  'company.basics.user.code': '人員卡號',
  'company.basics.user.delete.tips': '確定要删除選定的人員信息嗎？',
  'company.basics.event.list': '事件清單',
  'company.basics.event.name': '事件名稱',
  'company.basics.event.code': '事件卡號',
  'company.basics.user.event.tips': '確定要删除選定的事件信息嗎？',
  // 设备
  'company.device.change.end.time.success': '修改設備結束時間成功',
  'company.device.number': '設備號碼',
  'company.device.end.time': '結束時間',
  'company.device.belonging.enterprise': '所屬企業',
  'company.device.delete.info.tips': '確定要删除選定的設信息嗎？',
  'company.device.change.end.time.tips': '確定要修改註冊設備結束時間？',
  'company.device.register.count': '設備注册數量',
  'company.device.register.info.statistics': '注册設備信息統計',
};
