export default {
  'header.guest': '遊客',
  'header.welcome': 'Hi, 歡迎你',
  'header.modifyPassword': '修改密碼',
  'header.logout': '退出登錄',
  'header.read': '讀取數據',
  'header.timing': '設備校時',
  'header.report': '巡檢報告',
  'header.history': '歷史數據',
  'header.chart': '統計圖表',
  'header.fullScreen': '切換全屏',
  'header.theme': '切換主題',
  'header.theme.light': '亮色主題',
  'header.theme.dark': '暗色主題',
  'header.component.download': '通訊組件',

  'header.password.original': '原密碼',
  'header.password.original.placeholder': '請輸入原密碼',
  'header.password.new': '新密碼',
  'header.password.new.placeholder': '請輸入新密碼',
  'header.password.confirm': '確認密碼',
  'header.password.confirm.placeholder': '請輸入確認密碼',
  'header.password.notMatch': '兩次密碼輸入不同，請確認',
  'header.password.same': '原密碼與新密碼相同，無需更改密碼',
  'header.password.blank': '密碼不能為空格',

  'header.password.modify.success': '修改密碼成功，將在5秒鐘後重新登錄系統',

  'header.device.connected': '設備已連接',
  'header.device.disconnected': '設備未連接',
  'header.modifyEmail': '修改郵箱',
};
