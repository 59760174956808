export default {
  'alarm.notification.title': '报警提醒',
  'alarm.notification.desc': '收到 {count} 条报警数据，请注意处理...',

  'alarm.notification.view': '查看详情',
  'alarm.notification.ignore': '忽略报警',

  'alarm.notification.list': '待处理报警',
  'alarm.notification.readAll': '全部处理',
  'alarm.notification.readAll.confirm': '是否处理全部报警？',

  'alarm.notification.read.title': '提示',
  'alarm.notification.read.done': '报警已标记为处理',
  'alarm.notification.read.error': '处理报警时发生错误',

  'alarm.manual': '手动报警',
  'alarm.low': '低电报警',
  'alarm.omit': '漏检报警',
  'alarm.tilt': '倾斜报警',
  'alarm.motionless': '静止报警',
  'alarm.collision': '摔碰报警',
  'alarm.dismantle': '开壳报警',
  'alarm.out': '出圈报警',
  'alarm.in': '进圈报警',

  'alarm.choice.no': '无报警功能',
  'alarm.choice.web': '仅网页端报警',
  'alarm.choice.app': '仅手持端报警',
  'alarm.choice.all': '网页及手持端均报警',
  'alarm.notification.title.desc': '是否在网页端或手持端包含报警数据提醒功能',
};
