export default {
  'sound.title': '巡检语音信息',
  'sound.no': '序号',
  'sound.name': '语音内容',
  'sound.name.placeholder': '请输入语音内容',
  'sound.dept.placeholder': '请选择区域',
  'sound.table.operate': '操作',
  'sound.table.edit': '编辑',
  'sound.table.add': '增加语音',
  'sound.table.home': '返回首页',
  'sound.table.voice': '生成语音库',
  'sound.table.clear': '清除设备语音',
  'sound.table.voice.success': '语音库生成成功！',
  'sound.table.voice.err': '语音库生成失败！',
  'sound.table.delete': '删除',
  'sound.table.selected': '已选择',
  'sound.table.item': '项',
  'sound.modal.delconfirm': '确定要删除选择的数据吗？',
  'sound.table.deletesuccess': '数据删除成功',
  'sound.modal.ok': '确定',
  'sound.modal.cancel': '取消',
  'sound.modal.error': '操作失败',
  'sound.message.loading.add': '正在添加...',
  'sound.message.loading.edit': '正在配置...',
  'sound.genvoice.loading': '语音文件生成中，请稍等...',
  'sound.genvoice.invalidDevice': '当前接入设备不支持语音功能！',
};
