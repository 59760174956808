export default {
  'dashboard.charts': 'Διαγράμματα',
  'dashboard.charts.title.trend': 'Τάση ρυθμού πιστοποιημένων',
  'dashboard.charts.title.rank': 'Λίστα βαθμονόμησης ρυθμού πιστοποιημένων',
  'dashboard.charts.dept.placeholder': 'Παρακαλώ επιλέξτε ένα Τμήμα',
  'dashboard.charts.rank.dept': 'Τμήμα',
  'dashboard.charts.rank.plan': 'Πλάνο',
  'dashboard.charts.rank.guard': 'Φύλακας',
  'dashboard.charts.rank.site': 'Τοποθεσία',
  'dashboard.charts.date.today': 'Σήμερα',
  'dashboard.charts.date.week': 'Εβδομάδα',
  'dashboard.charts.date.month': 'Μήνας',
  'dashboard.charts.orderby.asc': 'Αύξουσα',
  'dashboard.charts.orderby.desc': 'Φθίνουσα',
  'dashboard.statistics.type': 'Τύπος',
  'dashboard.statistics.type.qualified': 'Πιστοποιημένα',
  'dashboard.statistics.type.unqualified': 'Παράλειψη',
  'dashboard.statistics.type.todo': 'Προς Ενέργεια',
  'dashboard.statistics.columnes.count': 'Ποσότητα',
  'dashboard.statistics.columnes.rate': 'Ποσοστό',
  'dashboard.statistics.title.percent': 'Σημερινά Στατιστικά',
  'dashboard.latestHistories.columnes.site': 'Τοποθεσία',
  'dashboard.latestHistories.columnes.guard': 'Φύλακας/Συσκευή',
  'dashboard.latestHistories.columnes.info': 'Συμβάν',
  'dashboard.latestHistories.columnes.remark': 'Σχόλιο',
  'dashboard.latestHistories.columnes.time': 'Ώρα',
  'dashboard.latestHistories.columnes.extra': 'Media',
  'dashboard.latestHistories.title.trend': 'Πρόσφατη μεταφόρτωση',
  'dashboard.latestAlarms.title.alarmData': 'Πρόσφατοι Συναγερμοί',
  'dashboard.latestLogs.columnes.action': 'Οθόνη & Ενέργεια',
  'dashboard.latestLogs.columnes.time': 'Ώρα',
  'dashboard.latestLogs.columnes.reader': 'Συσκευή',
  'dashboard.latestLogs.columnes.user': 'Χρήστης',
  'dashboard.latestLogs.title.log': 'Πρόσφατες Λειτουργίες',
  'dashboard.card.extra.refresh': 'Ανανέωση',
  'dashboard.card.extra.history': 'Μετάβαση στη σελίδα Ιστορικού',
  'dashboard.card.extra.alarm': 'Μετάβαση στη σελίδα Συναγερμών',
  'dashboard.card.extra.log': 'Μετάβαση στη σελίδα καταγραφής',
};
