export default {
  'map.alarmcontentinfo.alarmdetail': "Détails de l'alarme:",
  'map.alarmcontentinfo.unkonwdetail': 'Détails inconnus',
  'map.alarmcontentinfo.iknow': 'Poignée',

  'map.devicecontentinfo.unkonwperson': 'Garde anonyme',
  'map.devicecontentinfo.track.timerange': 'Plage de temps pour la lecture des données orbitales',
  'map.devicecontentinfo.track.speed': 'Vitesse(km/h)',
  'map.devicecontentinfo.track.begin': 'Replay',

  'map.floatingtoolbar.track.replay': 'Replay',
  'map.floatingtoolbar.track.pause': 'Pause',
  'map.floatingtoolbar.track.resume': 'Reprise',
  'map.floatingtoolbar.track.clear': "Le retrait",
  'map.floatingtoolbar.track.resetlimit': 'Réinitialiser les conditions',

  'map.device.deviceno': 'ID du périphérique',
  'map.search.nocondition.msg': 'Veuillez saisir les critères de recherche!',
  'map.track.notime.msg': 'Veuillez sélectionner la plage de temps de lecture de la piste!',
  'map.track.empty': 'Aucune donnée de suivi trouvée',
  'map.site.sitename': 'Nom du site',
  'map.site.address': 'Adresse',
  'map.module.title': 'Surveillance',
  'map.sitecontentinfon.unknowdept': 'Département inconnu.',
  'map.sitecontentinfon.unknowperson': 'Garde anonyme',
  'map.sitecontentinfon.actionhistory': 'Historique',
  'map.topcomponent.select.plan': 'Veuillez sélectionner un calendrier',
  'map.topcomponent.device.onlinetime': 'Temps en ligne (minutes)',
  'map.topcomponent.screen.full': "Basculer l'écran complet",

  'map.realdata.table.deptname': 'Ministère.',
  'map.realdata.table.sitename': 'Lieu',
  'map.realdata.table.guardname': 'Garde',
  'map.realdata.table.guardcode': 'Installations',
  'map.realdata.table.happentime': 'Temps',

  'map.realdata.changepage.old': "Passer à l'ancienne carte de surveillance",
  'map.realdata.changepage.new': 'Passer à une nouvelle carte de surveillance',
  'map.realdata': 'Données en temps réel',

  'map.google.normal': 'Normal',
  'map.google.satellite': 'Satellite',

  'map.videomodal.title': 'Vidéo',
  'map.audiomodal.title': 'Audio',
  'map.button.close': 'Fermer',

  'map.playback': 'Lecture de la piste',
  'map.playback.over': 'Fin de la piste',
  'map.playback.mile': 'M',
  'map.playback.timespan':
    'La piste ne peut pas jouer plus de 2 jours, veuillez sélectionner à nouveau la plage de dates',

  'map.load.timeout': 'Le chargement de la carte a expiré et la page sera rechargée',

  'map.cluster.more': 'Zoomez pour voir plus de balises...',
  'map.site.add.confirm':
    'Êtes-vous sûr de vouloir ajouter tous les points de contrôle sous le plan actuellement sélectionné à la carte?',
  'map.site.del.confirm':
    'Êtes-vous sûr de vouloir supprimer tous les points de contrôle sous le plan actuellement sélectionné de la carte?',
  'map.site.del.info': "Veuillez d'abord sélectionner un forfait",
  'map.planImg.warn.planNotImgMsg': 'Pas de schéma pour le plan actuel. Télécharger',
  'map.planImg.upload.label': 'Importez vos images',
  'map.planImg.warn.siteNotHaveFlag':
    'Point d’inspection actuellement prévu temporairement non marqué',
  'map.planImg.warn.outOfRange':
    'L’emplacement de marquage est en dehors de la gamme schématique, veuillez marquer à nouveau',
  'map.planImg.mode.update': 'Le mode édition',
  'map.planImg.btn.addFlag': 'Nouveaux marqueurs ajoutés',
  'map.planImg.btn.clearFlagState': 'Annuler l’état de ponctuation',
  'map.planImg.btn.uptImg': 'Modifier une image',
  'map.planImg.btn.delFlag': 'Supprimer le tag',
};
