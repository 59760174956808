export default {
  'comm.1': 'Μη υποστηριζόμενη λειτουργία',
  'comm.4096': 'Άγνωστος αναγνώστης',
  'comm.4097': 'Η συσκευή δεν βρέθηκε',
  'comm.4098': 'Αποτυχία εγγραφής',
  'comm.4099': 'Αποτυχία ανάγνωσης συσκευής',
  'comm.4100': 'Αποτυχία ανοίγματος συσκευής',
  'comm.4101': 'Αποτυχία σύνδεσης',
  'comm.4102': 'Λάθος τύπος συσκευής',
  'comm.4104': 'Το όνομα αρχείου είναι κενό',
  'comm.4105': 'Αποτυχία δημιουργίας αρχείου',
  'comm.4150':
    'Η βάση δεδομένων είναι γεμάτη, διαγράψτε το δακτυλικό αποτύπωμα πριν από τη συλλογή',
  'comm.4500': 'Διαμορφώστε πρώτα τη φωνή και δημιουργήστε το αρχείο φωνητικής βιβλιοθήκης',
};
