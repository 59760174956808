export default {
  'comm.1': '當前接入設備不支持此功能',
  'comm.4096': '未知設備',
  'comm.4097': '未檢測到設備',
  'comm.4098': '設備寫入數據失敗',
  'comm.4099': '讀取設備失敗',
  'comm.4100': '打開設備失敗',
  'comm.4101': '連接失敗',
  'comm.4102': '設備類別錯誤',
  'comm.4104': '文件名稱爲空',
  'comm.4105': '創建文件失敗',
  'comm.4113': '初始化失敗',
  'comm.4150': '數據庫已滿，請刪除指紋後再進行採集',
  'comm.4500': '請先配置語音並生成語音庫文件',
};