export default {
  'site.title': 'Ρύθμιση Σημείων Ελέγχου',
  'site.btn.batch': 'Μαζική Ανάγνωση',
  'site.btn.add': 'Προσθήκη Σημείο Ελέγχου',
  'site.btn.addgps': 'Προσθήκη GPS Σημείου Ελέγχου',
  'site.btn.addcoll': 'Προσθήκη Συλλογής Σημείων Ελέγχου',
  'site.btn.edit': 'Επεξεργασία',
  'site.btn.del': 'Διαγραφή Σημείων Ελέγχου',
  'site.btn.download': 'Λήψη δεδομένων',
  'site.btn.import': 'Εισαγωγή Σημείου Ελέγχου',
  'site.btn.export': 'Εξαγωγή Σημείου Ελέγχου',
  'site.btn.assign': 'Αντιστοίχιση περιεχομένου',
  'site.btn.move': 'Μεταφορά Σημείου Ελέγχου',
  'site.btn.rnd': 'Τυχαία καταχωρημένα σημεία ελέγχου',
  'site.btn.rnd.del': 'Διαγραφή όλων των σημείων ελέγχου',
  'site.modal.position': 'Γεωγραφική θέση',
  'site.table.code': 'Σημείο Ελέγχου ID',
  'site.table.name': 'Ονομασία Σημείων Ελέγχου',
  'site.table.type': 'Τύπος Σημείου Ελέγχου',
  'site.table.longitude': 'Γεωγραφικό μήκος',
  'site.table.latitude': 'Γεωγραφικό Πλάτος',
  'site.table.range': 'Διάστημα σφάλματος(m)',
  'site.table.voice': 'Φων.Μήνυμα',
  'site.table.site': 'Θέση',
  'site.table.app.content': 'Τίτλος περιεχομένου',
  'site.table.app.des': 'Περιγραφή περιεχομένου',
  'site.table.app.submission': 'Μέθοδος υποβολής',
  'site.table.app.qr': 'Qr Code',
  'site.table.app.bluetooth': 'Bluetooth',
  'site.table.app.em': 'EM κωδικός',
  'site.table.app.single': 'Μονό',
  'site.table.app.multiple': 'Πολλαπλά',
  'site.table.app.enter': 'Εισάγετε',
  'site.table.app.colluser': 'Συλλέκτες',
  'site.table.app.colltime': 'Χρόνος συλλογής',
  'site.table.voice.clear': 'Διαγραφή φων.μηνυμάτων σε συσκευή',
  'site.table.voice.clear.success': 'Επιτυχής διαγραφή φων.μηνυμάτων',
  'site.table.voice.clear.failed': 'Αποτυχία διαγραφής φων.μηνυμάτων',
  'site.table.voice.clear.confirm': 'Επιβεβαιώνετε διαγραφή φωνητικών μηνυμάτων ?',
  'site.table.edit': 'Επεξεργασία',
  'site.input.site.placeholder': 'Παρακαλώ εισάγετε ονομασία σημείου ελέγχου',
  'site.input.code.placeholder': 'Παρακαλώ εισάγετε ID σημείων ελέγχου',
  'site.Transfer.Unselected': 'Μη επιλεγμένα φων.μηνύματα',
  'site.Transfer.selected': 'Επιλεγμένα φων.μηνύματα',
  'site.headerTitle.GPS': 'Όλα τα σημεία ελέγχου GPS',
  'site.headerTitle.All': 'Λίστα ρυθμισμένων φων.μηνυμάτων και σημείων ελέγχου GPS',
  'site.notification.filtercode':
    'Τα αρχεία με διπλούς κωδικούς σημείων ελέγχου έχουν φιλτραριστεί',
  'site.notification.filtername':
    'Τα αρχεία με διπλούς κωδικούς σημείων ελέγχου έχουν φιλτραριστεί',
  'site.notification.download.success': 'Επιτυχής λήψη δεδομένων',
  'site.notification.mostsite': 'Δεν μπορούν να επιλεγούν πάνω από 1000 σημεία ελέγχου',
  'site.notification.mostgpssite': 'Δεν μπορούν να επιλεγούν πάνω από 500 σημεία ελέγχου GPS',
  'site.notification.delposition':
    'Είστε βέβαιοι ότι θέλετε να διαγράψετε τις συντεταγμένες τοποθεσίας στον χάρτη;',
  'site.table.moveup': 'Μετακινήστε προς τα πάνω',
  'site.table.movedown': 'Κινήστε προς τα κάτω',
};
