export default {
  'dashboard.charts': 'Charts',
  'dashboard.charts.title.trend': 'Qualified Rate Trend',
  'dashboard.charts.title.rank': 'Qualified Rate Ranking List',
  'dashboard.charts.dept.placeholder': 'Please select a dept.',
  'dashboard.charts.rank.dept': 'Dept.',
  'dashboard.charts.rank.plan': 'Plan',
  'dashboard.charts.rank.guard': 'Guard',
  'dashboard.charts.rank.site': 'Site',
  'dashboard.charts.date.today': 'Today',
  'dashboard.charts.date.week': 'Week',
  'dashboard.charts.date.month': 'Month',
  'dashboard.charts.orderby.asc': 'Ascending',
  'dashboard.charts.orderby.desc': 'Descending',
  'dashboard.statistics.type': 'Type',
  'dashboard.statistics.type.qualified': 'Qualified',
  'dashboard.statistics.type.unqualified': 'Omit',
  'dashboard.statistics.type.todo': 'To-do',
  'dashboard.statistics.columnes.count': 'Quantity',
  'dashboard.statistics.columnes.rate': 'Percentage',
  'dashboard.statistics.title.percent': 'Today Statistics',
  'dashboard.latestHistories.columnes.site': 'Site',
  'dashboard.latestHistories.columnes.guard': 'Guard/Device',
  'dashboard.latestHistories.columnes.info': 'Event',
  'dashboard.latestHistories.columnes.remark': 'Remark',
  'dashboard.latestHistories.columnes.time': 'Time',
  'dashboard.latestHistories.columnes.extra': 'Media',
  'dashboard.latestHistories.title.trend': 'Recent Uploading',
  'dashboard.latestAlarms.title.alarmData': 'Recent Alarms',
  'dashboard.latestLogs.columnes.action': 'Screen & Action',
  'dashboard.latestLogs.columnes.time': 'Time',
  'dashboard.latestLogs.columnes.reader': 'Device',
  'dashboard.latestLogs.columnes.user': 'User',
  'dashboard.latestLogs.title.log': 'Recent Operations',
  'dashboard.card.extra.refresh': 'Refresh',
  'dashboard.card.extra.history': 'Goto history page',
  'dashboard.card.extra.alarm': 'Goto alarm page',
  'dashboard.card.extra.log': 'Goto log page',
};
