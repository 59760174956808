export default {
  'exception.fail': '請求失敗',
  'exception.unknow': '出現未知問題',
  'exception.network': '網路異常',
  'exception.network.desc': '網路發生異常，無法連接伺服器',

  'exception.403': '沒有權限可以訪問當前資源',
  'exception.404': '無法找到當前所請求的資源',
  'exception.500': '服務器好像遇到了一些問題',

  'exception.400': '發出的請求存在錯誤',
  'exception.401': '用戶憑證失效',
  'exception.502': '502',
  'exception.503': '503',
  'exception.504': '網關超時',

  'exception.crash': '我了個去，程式好像出了一些故障...',
  'exception.disconnect': '獲取伺服器資訊失敗...',
};
