export default {
  'sound.title': '巡檢語音信息',
  'sound.no': '序號',
  'sound.name': '語音內容',
  'sound.name.placeholder': '請輸入語音內容',
  'sound.dept.placeholder': '請選擇區域',
  'sound.table.operate': '操作',
  'sound.table.edit': '編輯',
  'sound.table.add': '增加語音',
  'sound.table.home': '返回首頁',
  'sound.table.voice': '生成語音庫',
  'sound.table.clear': '清除設備語音',
  'sound.table.voice.success': '語音庫生成成功！',
  'sound.table.voice.err': '語音庫生成失敗！',
  'sound.table.delete': '刪除',
  'sound.table.selected': '已選擇',
  'sound.table.item': '項',
  'sound.modal.delconfirm': '確定要刪除選擇的數據嗎？',
  'sound.table.deletesuccess': '數據刪除成功',
  'sound.modal.ok': '確定',
  'sound.modal.cancel': '取消',
  'sound.modal.error': '操作失敗',
  'sound.message.loading.add': '正在添加...',
  'sound.message.loading.edit': '正在配置...',
  'sound.genvoice.loading': '語音文件生成中，請稍等...',
  'sound.genvoice.invalidDevice': '當前接入設備不支持語音功能！',
};
