export default {
  'content.title': 'Ρύθμιση Περιεχομένων',
  'content.btn.add': 'Προσθήκη Περιεχομένων',
  'content.btn.site': 'Επιλογή Σημείου Ελέγχου',
  'content.btn.choice': 'Προσθήκη Επιλογών',
  'content.label.choice': 'Επιλογές περιεχομένων',
  'content.input.content.placeholder': 'Παρακαλώ εισάγετε περιεχόμενο',
  'content.input.des.placeholder': 'Παρακαλώ εισάγετε περιγραφή περιεχομένου',
  'content.input.choice.placeholder': 'Παρακαλώ εισάγετε επιλογές περιεχομένου',
  'content.notification.description':
    'Ο τίτλος περιεχομένου περιπολίας υπάρχει ήδη, Εισάγετε πάλι.',
  'content.notification.choice.blank':
    'Όταν η μέθοδος υποβολής είναι απλής ή πολλαπλής επιλογής, πρέπει να εισαχθούν οι επιλογές περιεχομένου!',
  'content.table.moveup': 'Μετακινήστε προς τα πάνω',
  'content.table.movedown': 'Κινήστε προς τα κάτω',
};
