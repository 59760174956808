export default {
  'event.title': 'Ρύθμιση Συμβάντων',
  'event.btn.add': 'Προσθήκη Συμβάντων',
  'event.btn.import': 'Εισαγωγή Συμβάντος',
  'event.btn.export': 'Εξαγωγή Συμβάντος',
  'event.table.code': 'ID Συμβάντος',
  'event.table.name': 'Ονομασία Συμβάντος',
  'event.table.simple': 'Απλό Συμβάν',
  'event.table.common': 'Κοινό Συμβάν',
  'event.input.code.placeholder': 'Παρακαλώ εισάγετε ID συμβάντος',
  'event.input.name.placeholder': 'Παρακαλώ εισάγετε ονομασία συμβάντος',
  'event.table.moveup': 'Μετακινήστε προς τα πάνω',
  'event.table.movedown': 'Κινήστε προς τα κάτω',
};
