import { getDvaApp, getLocale } from 'umi';
import { reloadAuthorized } from './Authorized';
import { getUrlParam, getCloudVersion, getSoftwareVersion } from './utils';

export function isGuest() {
  // eslint-disable-next-line  no-underscore-dangle
  return getDvaApp()._store.getState().login.role === 'guest';
}

export function getRequestPrefix() {
  // TODO: 后期移除请求前缀判断逻辑，改为统一在request里面处理
  return isGuest() ? 'mock' : 'api';
}

export function setAuthority(authority) {
  localStorage.setItem('modules', authority);
  reloadAuthorized();
}

export function getAuthority() {
  const modules = localStorage.getItem('modules');
  return modules ? modules.split(',') : [];
}

export function setDevices(devices) {
  localStorage.setItem('devices', JSON.stringify(devices));
}

export function getDevices() {
  const devices = localStorage.getItem('devices');
  return devices ? JSON.parse(devices) : [];
}

export function setDeviceFlag(deviceFlag) {
  localStorage.setItem('deviceFlag', deviceFlag);
}

export function getDeviceFlag() {
  return localStorage.getItem('deviceFlag');
}

export function getProfile() {
  const profile = localStorage.getItem('profile');
  return profile ? JSON.parse(profile) : {};
}

export function setProfile(profile) {
  localStorage.setItem('profile', JSON.stringify(profile));
}

export function clearProfile() {
  localStorage.removeItem('profile');
}

export function setToken(token) {
  localStorage.setItem('token', token);
}

export function getToken() {
  // 如果在url中指定了token，则优先从url中取得token
  return getUrlParam('token') || localStorage.getItem('token');
}

export function removeToken() {
  localStorage.removeItem('token');
}

export function getDateFormat() {
  if (isGuest()) return 'YYYY-MM-DD';
  const dateFormat = getProfile().dateFormat || 'yyyy-MM-dd';
  return dateFormat.toUpperCase();
}

export function getShortDateFormat() {
  if (isGuest()) return 'MM-DD';
  const sdf = getProfile().dateFormat;
  // profile内存储的dateFormat可能不存在（如之前从未登录过注册用户）
  if (!sdf) return 'MM-DD';
  const dateFormat =
    sdf.substring(0, 4) === 'yyyy' ? sdf.substring(5) : sdf.substring(0, 5) || 'MM-dd';
  return dateFormat.toUpperCase();
}

export function getCountryCode() {
  // if (true) return getLocale().startsWith('zh') ? '86' : '';
  if (isGuest()) return getLocale().startsWith('zh') ? '86' : '';
  return getProfile().countryCode;
}

export function getCompanyID() {
  const profile = JSON.stringify(localStorage.profile);
  return profile.companyID;
}

export function setTheme(theme) {
  localStorage.setItem('theme', theme);
}

export function getTheme() {
  return localStorage.getItem('theme') || 'light';
}

export function setMapCenter(lnglat) {
  localStorage.setItem('mapcenter', JSON.stringify(lnglat));
}

export function getMapCenter() {
  let defaultMapCenter = JSON.stringify({
    longitude: 123.485956,
    latitude: 41.698418,
  });
  if (
    getCloudVersion() === 'Overseas' ||
    getSoftwareVersion() === 'M1.0' ||
    getProfile().countryCode !== '86'
  ) {
    defaultMapCenter = JSON.stringify({
      longitude: 164.920814,
      latitude: 26.896416,
    });
  }
  return localStorage.getItem('mapcenter') ? localStorage.getItem('mapcenter') : defaultMapCenter;
}

export function setMapZoom(zoom) {
  localStorage.setItem('mapzoom', zoom);
}

export function getMapZoom() {
  const defaultZoom = 20;
  return localStorage.getItem('mapzoom') ? localStorage.getItem('mapzoom') : defaultZoom;
}
