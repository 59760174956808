export default {
  'comm.1': 'Fonctions non prises en charge',
  'comm.4096': 'Lecteurs inconnus',
  'comm.4097': 'Périphérique introuvable',
  'comm.4098': "Je ne peux pas l'écrire",
  'comm.4099': "Impossible de lire l'appareil",
  'comm.4100': "Impossible d'ouvrir l'appareil",
  'comm.4101': 'Impossible de se connecter',
  'comm.4102': 'Mauvais type de périphérique',
  'comm.4104': 'Le nom du fichier est vide',
  'comm.4105': 'Impossible de créer le fichier',
  'comm.4150':
    "La base de données est pleine. Veuillez supprimer l'empreinte digitale avant de la saisir.",
  'comm.4500': "Veuillez d'abord configurer la voix et générer un fichier de bibliothèque vocale",
};
