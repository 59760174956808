export default {
  'map.alarmcontentinfo.alarmdetail': 'Alarm Details:',
  'map.alarmcontentinfo.unkonwdetail': 'Unknown Details',
  'map.alarmcontentinfo.iknow': 'handle',

  'map.devicecontentinfo.unkonwperson': 'Unknown Guard',
  'map.devicecontentinfo.track.timerange': 'Time range of track data playback',
  'map.devicecontentinfo.track.speed': 'Speed(km/h)',
  'map.devicecontentinfo.track.begin': 'Playback',

  'map.floatingtoolbar.track.replay': 'Replay',
  'map.floatingtoolbar.track.pause': 'Pause',
  'map.floatingtoolbar.track.resume': 'Resume',
  'map.floatingtoolbar.track.clear': 'Quit',
  'map.floatingtoolbar.track.resetlimit': 'Reset Condition',

  'map.device.deviceno': 'Device ID',
  'map.search.nocondition.msg': 'Please enter search criteria!',
  'map.track.notime.msg': 'Please select the track playback time range!',
  'map.track.empty': 'No track data found',
  'map.site.sitename': 'Site Name',
  'map.site.address': 'Address',
  'map.module.title': 'Monitoring',
  'map.sitecontentinfon.unknowdept': 'Unknown Dept.',
  'map.sitecontentinfon.unknowperson': 'Unknown Guard',
  'map.sitecontentinfon.actionhistory': 'History',
  'map.topcomponent.select.plan': 'Please choose a plan',
  'map.topcomponent.device.onlinetime': 'Online Time (min.)',
  'map.topcomponent.screen.full': 'Toggle full screen',

  'map.realdata.table.deptname': 'Dept.',
  'map.realdata.table.sitename': 'Site',
  'map.realdata.table.guardname': 'Guard',
  'map.realdata.table.guardcode': 'Device',
  'map.realdata.table.happentime': 'Time',

  'map.realdata.changepage.old': 'Switch to the old monitor map',
  'map.realdata.changepage.new': 'Switch to the new monitor map',
  'map.realdata': 'Real-time Data',

  'map.google.normal': 'Normal',
  'map.google.satellite': 'Satellite',

  'map.videomodal.title': 'video',
  'map.audiomodal.title': 'audio',
  'map.button.close': 'close',

  'map.playback': 'Track Playback',
  'map.playback.over': 'End of track playback',
  'map.playback.mile': 'meter',
  'map.playback.timespan':
    'The track playback time period cannot exceed 2 days, please reselect the date range',

  'map.load.timeout': 'Map load timeout, page will reload',

  'map.cluster.more': 'Zoom in to see more markers...',
  'map.site.add.confirm':
    'Are you sure you want to add all checkpoints under the currently selected plan to the map?',
  'map.site.del.confirm':
    'Are you sure you want to remove all checkpoints under the currently selected plan from the map?',
  'map.site.del.info': 'Please select a plan first',

  'map.planImg.warn.planNotImgMsg': 'The current plan does not have a schematic, please upload',
  'map.planImg.upload.label': 'Import picture',
  'map.planImg.warn.siteNotHaveFlag': 'The current planned inspection site is not marked',
  'map.planImg.warn.outOfRange':
    'Mark the location outside the scope of the diagram, please re-mark',
  'map.planImg.mode.update': 'Edit mode',
  'map.planImg.btn.addFlag': 'New tag',
  'map.planImg.btn.clearFlagState': 'unpunctuate',
  'map.planImg.btn.uptImg': 'Modify picture',
  'map.planImg.btn.delFlag': 'Delete tag',
};
