export default {
  'dashboard.charts': 'Graphiques',
  'dashboard.charts.title.trend': 'Tendance',
  'dashboard.charts.title.rank': 'Liste de classement',
  'dashboard.charts.dept.placeholder': 'Veuillez sélectionner un département.',
  'dashboard.charts.rank.dept': 'Département',
  'dashboard.charts.rank.plan': 'Plan',
  'dashboard.charts.rank.guard': 'Garde',
  'dashboard.charts.rank.site': 'Lieu',
  'dashboard.charts.date.today': "Aujourd'hui",
  'dashboard.charts.date.week': 'Semaine',
  'dashboard.charts.date.month': 'Mois',
  'dashboard.charts.orderby.asc': 'Promotion',
  'dashboard.charts.orderby.desc': 'En déclin',
  'dashboard.statistics.type': 'Type',
  'dashboard.statistics.type.qualified': 'Qualified',
  'dashboard.statistics.type.unqualified': 'Omis',
  'dashboard.statistics.type.todo': 'Fais - le',
  'dashboard.statistics.columnes.count': 'Nombre',
  'dashboard.statistics.columnes.rate': 'Pourcentage',
  'dashboard.statistics.title.percent': "Statistiques d'aujourd'hui",
  'dashboard.latestHistories.columnes.site': 'Lieu',
  'dashboard.latestHistories.columnes.guard': 'Dispositifs de protection',
  'dashboard.latestHistories.columnes.info': 'Événements',
  'dashboard.latestHistories.columnes.remark': 'Commentaires',
  'dashboard.latestHistories.columnes.time': 'Personnel temporaire',
  'dashboard.latestHistories.columnes.extra': 'Médias',
  'dashboard.latestHistories.title.trend': 'Téléchargement récent',
  'dashboard.latestAlarms.title.alarmData': 'Alertes récentes',
  'dashboard.latestLogs.columnes.action': 'Écran et action',
  'dashboard.latestLogs.columnes.time': 'Personnel temporaire',
  'dashboard.latestLogs.columnes.reader': 'Installations',
  'dashboard.latestLogs.columnes.user': 'Utilisateurs',
  'dashboard.latestLogs.title.log': 'Mesures prises récemment',
  'dashboard.card.extra.refresh': 'Rafraîchir',
  'dashboard.card.extra.history': 'Aller à la page historique',
  'dashboard.card.extra.alarm': 'Aller à la page des alarmes',
  'dashboard.card.extra.log': 'Aller à la page du Journal',
};
